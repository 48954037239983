<template>
  <div class="w-full animate-pulse">
    <div class="mt-3 h-[120px] w-full rounded-md bg-gray-200" />
    <div class="mt-3 h-[120px] w-full rounded-md bg-gray-200" />
    <div class="mt-3 h-[120px] w-full rounded-md bg-gray-200" />
    <div class="mt-3 h-[120px] w-full rounded-md bg-gray-200" />
    <div class="mt-3 h-[120px] w-full rounded-md bg-gray-200" />
    <div class="mt-3 h-[120px] w-full rounded-md bg-gray-200" />
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class NotificationPageSkeleton extends Vue {}
</script>
