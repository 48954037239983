import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginPage from "@/views/Enter/LoginPage.vue";
import ProfilePage from "@/views/Profile/ProfilePage.vue";
import SettingsPage from "@/views/Settings/SettingsPage.vue";
import NotificationsPage from "@/views/Notifications/NotificationsPage.vue";
import EditCard from "@/views/EditCard/EditCard.vue";
import AddCard from "@/views/AddCard/AddCard.vue";
import StoreApp from "@/views/Store/AndroidStore.vue";
import AppleStore from "@/views/Store/AppleStore.vue";
import SupportPage from "@/views/Settings/Pages/SupportPage.vue";
import SuccessInstall from "@/views/Store/SuccessInstall.vue";
import LoginYandex from "@/views/Enter/LoginYandex.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "Home",
    component: HomeView,
    meta: {
      layout: true,
      title: "Главная",
    },
  },
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
    meta: {
      layout: false,
      title: "Логин",
    },
  },
  {
    path: "/login-yandex",
    name: "LoginYandex",
    component: LoginYandex,
    meta: {
      layout: false,
      title: "Логин Yandex",
    },
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
    meta: {
      layout: true,
      title: "Профиль",
    },
  },
  {
    path: "/settings",
    name: "SettingsPage",
    component: SettingsPage,
    meta: {
      layout: true,
      title: "Настройки",
    },
  },
  {
    path: "/notifications",
    name: "NotificationsPage",
    component: NotificationsPage,
    meta: {
      layout: true,
      title: "Центр нотификаций",
    },
  },
  {
    path: "/edit-card/:cardId",
    name: "EditCard",
    component: EditCard,
    meta: {
      layout: true,
      title: "Редактирование карты",
    },
  },
  {
    path: "/new-card",
    name: "NewCard",
    component: AddCard,
    meta: {
      layout: true,
      title: "Добавление карты",
    },
  },
  {
    path: "/support-page",
    name: "SupportPage",
    component: SupportPage,
    meta: {
      layout: true,
      title: "Поддержка",
    },
  },
  {
    path: "/android",
    name: "AndroidStore",
    component: StoreApp,
    meta: {
      layout: false,
      title: "Force Play | Android",
    },
  },
  {
    path: "/success-install",
    name: "SuccessInstall",
    component: SuccessInstall,
    meta: {
      layout: false,
      title: "Force Play | Success Install",
    },
  },
  {
    path: "/ios",
    name: "AppleStore",
    component: AppleStore,
    meta: {
      layout: false,
      title: "Force Market | IOS",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title as string;
  }
});

export default router;
