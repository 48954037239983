import $api from "@/api/ApiInteceptor";
import { INotificationsResponse } from "@/struct/INotifications/INotification";
import { EWebMethod } from "@/struct/IRequest/IRequest";

export default class ApiNotifications {
  public static async getNotifications(
    skip = 10,
    limit = 10,
  ): Promise<INotificationsResponse | boolean> {
    try {
      const response = await $api.call(
        EWebMethod.GET,
        `/api/v5/mobile/driver/transactions?skip=${skip}&limit=${limit}`,
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }
}
