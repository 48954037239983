import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center text-sm text-grey text-opacity-30"
}
const _hoisted_2 = {
  key: 1,
  class: "flex justify-center text-sm text-grey text-opacity-30"
}
const _hoisted_3 = {
  key: 2,
  "data-cy": "notificationList",
  class: "mt-1"
}
const _hoisted_4 = { class: "flex items-start justify-between" }
const _hoisted_5 = { class: "w-[60%]" }
const _hoisted_6 = { class: "text-lg overflow-x-auto" }
const _hoisted_7 = { class: "text-sm" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "flex flex-col items-center" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { class: "text-sm mt-1" }
const _hoisted_17 = { class: "absolute bottom-1 right-4 text-xs" }
const _hoisted_18 = {
  key: 3,
  class: "text-center text-xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirm_modal = _resolveComponent("confirm-modal")!
  const _component_status_modal = _resolveComponent("status-modal")!
  const _component_notification_page_skeleton = _resolveComponent("notification-page-skeleton")!
  const _component_standart_template = _resolveComponent("standart-template")!

  return (_openBlock(), _createBlock(_component_standart_template, null, {
    default: _withCtx(() => [
      (_ctx.appVersion)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.appVersion), 1))
        : _createCommentVNode("", true),
      (_ctx.userData && _ctx.userData.phone_number)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Выплаты аккаунта: " + _toDisplayString(_ctx.userData.phone_number), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoadData && _ctx.notifications.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "relative mb-3 rounded-md bg-greyLight p-4"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(item.card_name), 1),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", null, "Списано: " + _toDisplayString(item.amount), 1),
                      (item.commission_withheld)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Отправлено: " + _toDisplayString(_ctx.mathCommission(item.amount, item.commission_withheld)), 1))
                        : _createCommentVNode("", true),
                      (item.commission_withheld)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Комиссия: " + _toDisplayString(item.commission_withheld), 1))
                        : _createCommentVNode("", true),
                      (item.partner_bank_name)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(`${item.partner_bank_name}`), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", {
                      class: _normalizeClass([
                _ctx.validateTransactionStatus(
                  item.status,
                  item.adapter_status,
                  'color',
                )
              , "flex w-full justify-center rounded-md py-1 px-2 text-sm text-white"])
                    }, _toDisplayString(_ctx.validateTransactionStatus(
                  item.status,
                  item.adapter_status,
                  "text",
                )), 3),
                    (item.date_time && _ctx.isActiveContactParent(item.date_time))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          onClick: ($event: any) => (_ctx.validateNotComingMoney(item)),
                          class: "cursor-pointer mt-3 flex w-full items-center justify-center rounded-full bg-background py-1 px-2 text-sm text-blue"
                        }, [
                          (
                  item.status === 'PAID' ||
                  item.status === 'IN_PROCESSING' ||
                  item.status === 'ALLOW_TRANSACTION'
                )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, " Не получил "))
                            : _createCommentVNode("", true),
                          (
                  (item.status === 'ERROR' &&
                    (item.adapter_status === 'SUCCESS' ||
                      item.adapter_status === 'NULL' ||
                      item.adapter_status === 'FAILED')) ||
                  item.status === 'BLOCK_TRANSACTION'
                )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, " Почему? "))
                            : _createCommentVNode("", true),
                          (
                  item.status === 'ERROR' && item.adapter_status === 'REVERTED'
                )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, " Не вернулось "))
                            : _createCommentVNode("", true)
                        ], 8, _hoisted_12))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, _toDisplayString(item.card_number), 1),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.formatDate(item.date_time)), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.isLoadData && _ctx.count <= 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, " У вас еще нет уведомлений, но они обязательно появятся "))
        : _createCommentVNode("", true),
      _createVNode(_component_confirm_modal, {
        state: _ctx.helpDialogModal,
        "confirm-button-text": _ctx.validateConfirmBtnText(),
        "decline-button-text": _ctx.validateDeclineBtnText(),
        htmlDescription: `${
        this.currentNotification?.status === 'ERROR' &&
        (this.currentNotification?.adapter_status === 'SUCCESS' ||
          this.currentNotification?.adapter_status === 'NULL' ||
          this.currentNotification?.adapter_status === 'FAILED')
          ? `${
              this.currentNotification?.adapter_status === 'SUCCESS'
                ? 'Деньги списались с баланса вашего кабинета.'
                : ''
            }
          ${
            this.currentNotification?.adapter_status === 'NULL' ||
            this.currentNotification?.adapter_status === 'FAILED'
              ? 'Деньги с баланса вашего кабинета <strong>НЕ СПИСАЛИСЬ</strong>.'
              : ''
          }  ${
              this.currentNotification?.status_description
                ? this.currentNotification?.status_description
                : ''
            }\n Если остались вопросы нажмите «Уточнить».\n Поддержка приложения работает с 10 до 22 по МСК.`
          : ''
      }${
        this.currentNotification?.status === 'ERROR' &&
        this.currentNotification?.adapter_status === 'REVERTED'
          ? `Деньги вернулись на баланс\n в Яндекс Про. ${
              this.currentNotification?.status_description
                ? this.currentNotification?.status_description
                : ''
            }\n Проверьте возврат средств, если возврата не произошло нажмите «Не получил» и свяжитесь с поддрежкой.\n Поддержка приложения работает с 10 до 22 по МСК. `
          : ''
      } ${
        this.currentNotification?.status === 'BLOCK_TRANSACTION'
          ? 'Транзакция на проверке в «Парке» (у партнера). Скоро статус исзменится, пожалуйста подождите.\n Если возникли вопросы нажмите «Уточнить».'
          : ''
      } ${
        this.currentNotification?.status === 'PAID' ||
        this.currentNotification?.status === 'ALLOW_TRANSACTION'
          ? 'Операция прошла успешно. Подождите минимум час и потом пришлите нам историю транзакции из приложения банка.\nЕсли транзакции там нет, дадим номер операции для поиска в вашем банке.'
          : ''
      }
       ${
         this.currentNotification?.status === 'IN_PROCESSING'
           ? 'Проведение платежа может затянуться. Пожалуйста подождите минимум час.\n Если возникли вопросы нажмите «Уточнить».'
           : ''
       }  `,
        onDecline: _cache[0] || (_cache[0] = ($event: any) => (_ctx.validateDeclineBtn())),
        onConfirm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateConfirmBtn()))
      }, null, 8, ["state", "confirm-button-text", "decline-button-text", "htmlDescription"]),
      _createVNode(_component_status_modal, {
        state: _ctx.statusModalDialog,
        "button-text": "Понял",
        description: _ctx.statusModalText,
        type: "warning",
        onConfirm: _cache[2] || (_cache[2] = ($event: any) => (_ctx.statusModalDialog = false))
      }, null, 8, ["state", "description"]),
      (!_ctx.isLoadData)
        ? (_openBlock(), _createBlock(_component_notification_page_skeleton, { key: 4 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}