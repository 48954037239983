<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ApiEnter from "@/api/ApiEnter";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import UtilStorage from "@/util/Common/UtilStorage";
import LoginStep from "@/components/Enter/LoginStep.vue";
import { ELoginStepYandex } from "@/struct/ELogin/ELoginStep";
import { Watch } from "vue-property-decorator";

@Options({
  components: { LoginStep, ButtonComponent },
})
export default class LoginYandex extends Vue {
  @Watch("password")
  watchPasswordInput(value: string) {
    const MAX_PASSWORD_LENGTH = 5;

    if (value.length > MAX_PASSWORD_LENGTH) {
      this.password = value.substring(0, MAX_PASSWORD_LENGTH);
    }

    if (value.length === MAX_PASSWORD_LENGTH && this.isNeedRegistration) {
      const isEasyPassword = value
        .split("")
        .map((char) => (value.match(new RegExp(char, "g")) || []).length > 2)
        .includes(true);

      if (isEasyPassword) {
        this.passwordError = "Пароль слишком легкий, попробуйте другой";
        this.password = "";
      } else {
        this.passwordError = "";
      }
    }
  }

  @Watch("passwordRepeat")
  watchPasswordRepeat(value: string): void {
    const MAX_PASSWORD_LENGTH = 5;

    if (value.length === MAX_PASSWORD_LENGTH && value !== this.password) {
      this.passwordRepeatError = "Пароли не совпадают, попробуйте еще раз";
      this.passwordRepeat = "";
    }
  }

  public hasError = false;
  public describeError = "";
  public password = "";
  public passwordRepeat = "";
  public hasRequest = false;

  public phoneFromMain = "";
  public phoneFromYandex = "";

  public currentStep = ELoginStepYandex.AuthYandex;
  public enumLoginSteps = ELoginStepYandex;

  public passwordRepeatError = "";
  public passwordError = "";

  private async loginWithYandex() {
    const code = this.$route.query.code;
    if (!code) {
      this.hasError = true;
      return;
    }

    const response = await ApiEnter.getYandexCode(code);

    if (typeof response != "boolean" && response?.access_token) {
      console.log("response", response);
      await UtilStorage.setItem("token", response.access_token);
      await UtilStorage.setItem("token_type", response.token_type);
      await UtilStorage.setItem("visitor_id", response.visitor_id);
      await this.$router.replace({ name: "Home" });
      return;
    }

    if (typeof response != "boolean" && response?.detail) {
      this.phoneFromYandex = response.username as string;

      if (response.detail == "USER_NOT_EXISTS") {
        this.describeError = `Вы хотели войти под номером ${this.phoneFromMain}, в вашем Яндекс привязан номер ${this.phoneFromYandex}. Войдите с другого аккаунта Яндекс либо поменяйте номер телефона в профиле Яндекс`;
      } else if (response.detail === "LIMITING_INPUT_ATTEMPTS") {
        this.describeError = "Превышено количество попыток авторизации";
      } else if (response.detail === "USER_NOT_ACTIVATE") {
        if (this.phoneFromMain != response.username) {
          this.currentStep = this.enumLoginSteps.ChooseNumber;

        } else {
          this.currentStep = ELoginStepYandex.CreatePass;
        }


      } else {
        this.describeError = `Код ошибки: ${response.detail}`;
      }
    }

    this.hasError = true;
  }

  public onFocusPassword(field: "password" | "passwordRepeat") {
    const input = this.$refs.input as unknown as HTMLInputElement[];
    const currentField = input[this[field].length];
    if (currentField) currentField.select();
  }

  public inputPasswordEvent(
    field: "password" | "passwordRepeat",
    value: number,
  ): void {
    this[field] += value;
    if (this[field].length < 5) {
      const input = this.$refs.input as unknown as HTMLInputElement[];
      input[this[field].length].select();
    }
  }

  public keyDownEvent(field: "password" | "passwordRepeat") {
    this[field] = this[field].substring(0, this[field].length - 1);
    const input = this.$refs.input as unknown as HTMLInputElement[];
    const currentInput = input[this[field].length];
    if (currentInput) {
      currentInput.select();
    }
  }

  public async activateUser() {
    const code = this.$route.query.code;
    const response = await ApiEnter.activateWithYandex(code, this.phoneFromYandex, this.password);

    if (typeof response != "boolean" && response?.access_token) {
      console.log("response", response);
      await UtilStorage.setItem("token", response.access_token);
      await UtilStorage.setItem("token_type", response.token_type);
      await UtilStorage.setItem("visitor_id", response.visitor_id);
      await this.$router.replace({ name: "Home" });
      return;
    }
  }

  async created() {
    this.phoneFromMain = await UtilStorage.getItem("phoneRegisterYandex") as string;
    await this.loginWithYandex();
  }
}
</script>

<template>
  <div class="login-container">
    <div class="login-form">
      <div v-auto-animate>
        <template v-if="currentStep === enumLoginSteps.ChooseNumber">
          <LoginStep :title="['Подтвердите действие']">
            <template v-slot:action>
              <p class="text-center">Вы хотели войти под номером {{ phoneFromMain }}, но в Яндекс указан номер
                {{ phoneFromYandex }}.
                <br>Продолжить регистрацию?</p>
              <div>

                <ButtonComponent
                  class="form-button mb-6"
                  font="normal"
                  rounded="md"
                  color="primary"
                  @clickAction="$router.replace({name: 'LoginPage'})"
                  title="Вернуться к авторизации"
                />
                <ButtonComponent
                  class="form-button mb-3"
                  font="normal"
                  rounded="md"
                  color="greyLight"
                  @clickAction="currentStep = enumLoginSteps.CreatePass"
                  title="Продолжить"
                />
              </div>
            </template>
          </LoginStep>
        </template>
        <template v-if="currentStep === enumLoginSteps.AuthYandex">
          <div class="text-center" v-if="!hasError">
            <p class="text-lg ">Еще немного..</p>
            <p>Завершаем авторизацию</p>
          </div>
          <div class="text-center space-y-3" v-else>
            <p class="text-lg">Произошла ошибка с авторизацией через Яндекс</p>
            <p>{{ describeError }}</p>
            <p>Попробуйте еще раз или войдите через другой метод</p>
            <ButtonComponent title="К авторизации" @clickAction="$router.replace('/')" />
          </div>
        </template>

        <template v-if="currentStep === enumLoginSteps.CreatePass">
          <LoginStep
            :title="['Придумайте свой пароль']"
            subtitle="Это НЕ СМС и НЕ ЗВОНОК"
            :attention="
              passwordError && passwordError.length ? passwordError : null
            "
          >
            <template v-slot:action>
              <div class="form-password">
                <div class="flex">
                  <template v-for="i in 5" :key="i">
                    <input
                      :class="{ active: password[i - 1] }"
                      @keyup.delete="keyDownEvent('password')"
                      @input="
                        inputPasswordEvent('password', $event.target.value)
                      "
                      ref="input"
                      type="password"
                      :value="password[i - 1]"
                      @focus="onFocusPassword('password')"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      autocomplete="off"
                      maxlength="1"
                      class="input-box focus:border focus:border-yellow-200"
                    />
                  </template>
                </div>
                <ButtonComponent
                  :disabled="password.length < 5"
                  class="form-button mb-6"
                  :loading="hasRequest"
                  font="normal"
                  type="submit"
                  rounded="md"
                  @clickAction="currentStep = enumLoginSteps.RepeatPass"
                  :color="password.length === 5 ? 'primary' : 'greyLight'"
                  title="Продолжить"
                />
              </div>
            </template>
          </LoginStep>

        </template>
        <template v-if="currentStep === enumLoginSteps.RepeatPass">
          <LoginStep
            :title="['Повторите пароль']"
            :attention="
              !passwordRepeat.length && passwordRepeatError
                ? passwordRepeatError
                : null
            "
          >
            <template v-slot:action>
              <div class="form-password">
                <div class="flex">
                  <template v-for="i in 5" :key="i">
                    <input
                      :class="{ active: passwordRepeat[i - 1] }"
                      ref="input"
                      @keyup.delete="keyDownEvent('passwordRepeat')"
                      @focus="onFocusPassword('passwordRepeat')"
                      :disabled="hasRequest"
                      @input="
                        inputPasswordEvent(
                          'passwordRepeat',
                          $event.target.value,
                        )
                      "
                      :value="passwordRepeat[i - 1]"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      type="password"
                      autocomplete="off"
                      maxlength="1"
                      class="input-box focus:border focus:border-yellow-200"
                    />
                  </template>
                </div>

                <ButtonComponent
                  :disabled="
                    passwordRepeat.length < 5 || passwordRepeat !== password
                  "
                  class="form-button"
                  font="normal"
                  type="submit"
                  :loading="hasRequest"
                  rounded="md"
                  :color="passwordRepeat.length === 5 ? 'primary' : 'greyLight'"
                  title="Продолжить"
                  @clickAction="activateUser"
                />
              </div>
            </template>
          </LoginStep>
        </template>
      </div>
    </div>
  </div>
</template>