import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-3 flex bg-[#FFDBD766] flex-col items-center text-center rounded-lg border-[#FFB9B6] border-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_component = _resolveComponent("button-component")!
  const _directive_auto_animate = _resolveDirective("auto-animate")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isTelegramConnect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createTextVNode(" Подключите Telegram, чтобы "),
            _createElementVNode("span", {
              class: "text-blue underline",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openVideo && _ctx.openVideo(...args)))
            }, "обезопасить"),
            _createTextVNode(" свои деньги ")
          ]),
          _createVNode(_component_button_component, {
            onClickAction: _ctx.openTelegram,
            title: "Установить",
            rounded: "lg",
            height: "10",
            class: "mt-2"
          }, null, 8, ["onClickAction"])
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_auto_animate]
  ])
}