<template>
  <standart-template>
    <PageHeader title="Редактирование карты" back-url="/home" />
    <div v-if="currentCard">
      <PayCards
        :id="currentCard.id"
        :type="currentCard.bank"
        :number-card="currentCard.number"
        :check-icon="false"
        color="#F5F5F8"
        :is-active="true"
        :title="currentCard.name"
        subtitle="Редактируемая карта"
        class="mt-4"
      />

      <div class="mt-4">
        <div class="text-grey text-sm">Название карты</div>
        <InputComponent
          class="mt-3"
          rounded="lg"
          maxlength="30"
          :border="false"
          background="bg-yellowLight"
          v-model="currentCard.name"
          :model="currentCard.name"
          @keyup="removeNumberInName()"
        />
      </div>

      <div class="mt-4">
        <ButtonComponent
          height="10"
          rounded="lg"
          :loading="hasRequest"
          :title="
            currentCard.name.length < 3 ? 'Короткое название' : 'Сохранить'
          "
          :disabled="currentCard.name.length < 3"
          @clickAction="onClickConfirm('save')"
        />
        <ButtonComponent
          class="mt-2"
          height="10"
          rounded="lg"
          color="red"
          :loading="hasRequest"
          text-color="white"
          title="Удалить карту"
          @clickAction="onClickConfirm('delete')"
        />
      </div>

      <StatusModal
        button-text="Отлично"
        type="success"
        description="Карта успешно удалена"
        :state="statusModalDialog"
        @confirm="
          statusModalDialog = false;
          $router.push('/home');
        "
      />

      <StatusModal
        button-text="Отлично"
        type="success"
        description="Карта успешно сохранена"
        :state="saveModalDialog"
        @confirm="
          saveModalDialog = false;
          $router.push('/home');
        "
      />

      <ConfirmModal
        decline-button-text="Нет"
        confirm-button-text="Да"
        :description="`Вы действительно хотите ${textModal} карту ${currentCard.number}`"
        @confirm="onClickActionCard"
        @decline="confirmModalDialog = false"
        :state="confirmModalDialog"
      />
    </div>
    <edit-card-skeleton v-else />
  </standart-template>
</template>

<script lang="ts">
import ApiCard from "@/api/ApiCard";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import InputComponent from "@/components/Common/InputComponent.vue";
import PageHeader from "@/components/Common/PageHeader.vue";
import PayCards from "@/components/HomePage/PayCard.vue";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import StatusModal from "@/components/Modals/StatusModal.vue";
import EditCardSkeleton from "@/components/Skeleton/EditCardSkeleton.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import { ICard } from "@/struct/IHomePage/ICabinetBalance";
import UtilError from "@/util/ValidateError/UtilError";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Action, Mutation } from "s-vuex-class";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    ConfirmModal,
    StatusModal,
    EditCardSkeleton,
    PageHeader,
    ButtonComponent,
    InputComponent,
    PayCards,
    StandartTemplate,
  },
})
export default class EditCard extends Vue {
  @Action("fetchUserCards") private fetchUserCards: (() => void) | undefined;
  @Mutation("setUserCards") private setUserCards:
    | ((value: undefined) => void)
    | undefined;

  private currentCardUUID!: string;
  private currentCard: ICard | null = null;
  public confirmModalDialog = false;
  public statusModalDialog = false;
  public saveModalDialog = false;
  public textModal: "сохранить" | "удалить" = "сохранить";
  private typeAction: "save" | "delete" = "save";
  public hasRequest = false;

  private onClickActionCard() {
    this.confirmModalDialog = false;

    if (this.typeAction === "save") {
      this.onClickSaveCard();
    }

    if (this.typeAction == "delete") {
      this.onClickDeleteCard();
    }
  }

  public onClickConfirm(type: "save" | "delete") {
    if (type === "save") {
      this.textModal = "сохранить";
      this.typeAction = "save";
    }

    if (type === "delete") {
      this.textModal = "удалить";
      this.typeAction = "delete";
    }

    this.confirmModalDialog = true;
  }

  public removeNumberInName() {
    if (!this.currentCard?.name) return;

    this.currentCard.name = this.currentCard.name.replace(
      /[^a-zA-Zа-яА-Я ]/g,
      "",
    );
  }

  private normalizeCardName(cardName: string): string {
    return cardName.trim();
  }

  private async onClickSaveCard() {
    if (!this.currentCard) return;

    this.hasRequest = true;
    const cardName = this.normalizeCardName(this.currentCard.name);
    const response = await ApiCard.updateCard(
      (this.currentCard as ICard).id as string,
      cardName,
    );

    if (response) {
      this.saveModalDialog = true;
      Haptics.impact({ style: ImpactStyle.Light });
    }
    this.hasRequest = false;
  }

  private async onClickDeleteCard() {
    if (!this.currentCard) return;

    this.hasRequest = true;
    const response = await ApiCard.deleteCard(this.currentCard.id as string);
    if (response.detail === "DRIVER_CARD_DELETED") {
      this.setUserCards?.(undefined);
      this.fetchUserCards?.();
      this.statusModalDialog = true;
      Haptics.impact({ style: ImpactStyle.Light });
    } else if (response.detail === "DRIVER_CARD_DELETE_ERROR") {
      this.setUserCards?.(undefined);
      this.fetchUserCards?.();
      UtilError.validateErrorMessage(response.detail);
      Haptics.impact({ style: ImpactStyle.Light });
    }
    this.hasRequest = false;
  }

  private async getCard() {
    const response = await ApiCard.GetCardByUUID(
      this.currentCardUUID as string,
    );
    if (typeof response != "boolean") {
      this.currentCard = response;
    }
  }

  created() {
    this.currentCardUUID = this.$route.params.cardId as string;
    this.getCard();
  }
}
</script>
