import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

export default class UtilStorage {
  public static platform = Capacitor.getPlatform();

  public static async setItem(key: string, value: string) {
    if (UtilStorage.platform === "web") {
      localStorage.setItem(key, value);
      return true;
    } else {
      await Preferences.set({
        key: key,
        value: value,
      });
      return true;
    }
  }

  public static async getItem(key: string): Promise<string | null> {
    if (UtilStorage.platform === "web") {
      const value = localStorage.getItem(key);
      return value;
    } else {
      const { value } = await Preferences.get({ key: key });

      return value;
    }
  }

  public static removeItem(key: string): void {
    if (UtilStorage.platform === "web") {
      localStorage.removeItem(key);
    } else {
      Preferences.remove({ key: key });
    }
  }
}
