<template>
  <div
    class="relative bg-white p-[20px] font-sans"
    :class="{ 'pointer-events-none': isWebview }"
  >
    <div
      v-if="isWebview"
      class="fixed top-0 left-1/2 w-[95%] mt-5 transform -translate-x-1/2 z-10 border-2 border-orange-400 rounded-xl p-3 bg-white shadow-sm"
    >
      <div class="text-lg text-center">
        Вы просматриваете страницу через встроенный браузер
      </div>
      <div class="flex space-x-2 items-center mt-2 flex-wrap">
        <p>Нажмите на иконку</p>
        <img src="@/assets/img/store/safari.svg" class="w-6 h-6" />
        <p>В правом нижнем углу</p>
      </div>
    </div>
    <div :style="{ filter: isWebview ? 'blur(1px)' : 'none' }">
      <section class="flex border-b border-[#E7E7E7] pb-8">
        <div class="mr-5 h-[126px] w-[126px] rounded-[20px]">
          <img src="@/assets/img/store/app-icon.png" alt="image" />
        </div>
        <div class="flex flex-col items-start justify-between">
          <div>
            <div class="text-[20px] tracking-wide">ФорсМани Pro</div>
            <div class="text-[12px] text-[#C4C4C4]">
              Работа в такси и доставке
            </div>
          </div>
          <button
            class="rounded-full bg-[#4593FC] p-1 px-3 font-medium tracking-wide text-white"
            @click="sendPromt"
          >
            Инструкция
          </button>
        </div>
      </section>

      <section class="mt-2 flex border-b border-[#E7E7E7] pb-2">
        <div class="flex flex-auto flex-col">
          <div class="text-center text-[14px] uppercase text-[#C4C4C4]">
            850 Отзывов
          </div>
          <div class="mt-2 text-center text-2xl font-medium text-[#A5A5A5]">
            4,7
          </div>
          <div class="mt-2 flex fill-[#C4C4C4] justify-center">
            <svg
              v-for="index in 5"
              :key="index"
              xmlns="http://www.w3.org/2000/svg"
              height="18"
              class="mr-1"
              viewBox="0 96 960 960"
              width="18"
            >
              <path
                d="m196 1028 75-324L19 486l332-28 129-306 129 306 332 28-252 218 76 324-285-173-284 173Z"
              />
            </svg>
          </div>
        </div>
        <div class="flex flex-auto flex-col">
          <div class="text-center text-[14px] uppercase text-[#C4C4C4]">
            Возраст
          </div>
          <div class="mt-2 text-center text-2xl font-medium text-[#A5A5A5]">
            4+
          </div>
        </div>
        <div class="flex flex-auto flex-col">
          <div class="text-center text-[14px] uppercase text-[#C4C4C4]">
            Размер
          </div>
          <div class="mt-2 text-center text-2xl font-medium text-[#A5A5A5]">
            17
          </div>
          <div class="mt-2 text-center text-[#A5A5A5] text-[14px]">МБ</div>
        </div>
      </section>

      <section
        class="hide-scrollbar mt-6 flex flex-nowrap overflow-x-auto whitespace-nowrap border-b border-[#E7E7E7] pb-6"
      >
        <div
          v-for="index in 5"
          :key="index"
          class="mr-3 w-[60px] min-w-[210px]"
        >
          <img
            class="rounded-[10px]"
            :src="require(`@/assets/img/store/mockups/ios/slide${index}.png`)"
            alt=" image"
          />
        </div>
      </section>

      <section id="gif" class="mt-6 flex justify-center flex-col items-center">
        <div class="text-center text-2xl font-medium">
          Инструкция по установке приложения
        </div>
        <div class="mt-2 text-xl">Не получилось установить?</div>
        <div
          @click="openWhatsApp"
          class="mt-2 flex flex-col items-center rounded-full bg-[#4593FC] px-4 py-1 text-sm font-medium text-white"
        >
          <div class="flex items-center">
            <div class="text-2xl">
              <i class="fab fa-whatsapp" />
            </div>
            <div class="ml-2 text-base">Написать Whatsapp</div>
          </div>
        </div>
        <img
          class="mt-2 max-w-[320px]"
          src="@/assets/img/store/mockups/ios/installation.gif"
          alt="gif"
        />
      </section>

      <section
        class="text-[#A0A0A0] text-[14px] mt-6 font-normal tracking-wide"
      >
        Вы могли сталкивались с ошибками при моменталках или зависанием платежей
        на 3-12 дней без возможности вернуть деньги обратно? В PRO версии
        появилась возможность моментально выбрать другой номер карты при
        возникновение самых распространенных ошибок переводов, а зависание
        платежей не происходит при использовании Системы Быстрых Платежей
        (отправка на карту по номеру телефона). Выводите деньги со всех крупных
        агрегатов заказов доставки и такси и концентрируйтесь на главном: вашей
        работе. Наше приложение не подведет!
      </section>

      <section class="mt-5 pb-3">
        <div class="text-[#4593FC] text-[15px]">Форс Технологии</div>
        <div class="text-[13px] text-[#A0A0A0]">Разработчик</div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Getter, Mutation } from "s-vuex-class";
import * as Sentry from "@sentry/vue";
import { Browser } from "@capacitor/browser";

export default class AppleStore extends Vue {
  @Getter("getDeferredPromo") private deferredPrompt: any;
  @Mutation("setDeferredPrompt") private setDeferredPrompt:
    | ((value: any) => void)
    | undefined;

  public isWebview = this.$IS_WEBVIEW;

  async sendPromt() {
    // When the user clicks the button, show the installation prompt
    if (this.deferredPrompt) {
      // Show the installation prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt

      const { outcome } = await this.deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
        Sentry.captureMessage("Success install online app Android");
      } else {
        console.log("User dismissed the install prompt");
      }
      // Clear the deferredPrompt variable since it can only be used once
      this.setDeferredPrompt?.(null);
    } else {
      const gif = document.getElementById("gif");

      (gif as HTMLElement).scrollIntoView({ behavior: "smooth" });
    }
  }

  public openWhatsApp() {
    const phoneNumber = encodeURI(process.env.VUE_APP_WHATSAPP_NUMBER);
    let message = `Проблема с установкой приложения через онлайн магазин. IOS. `;
    message = encodeURI(message);
    Browser.open({
      url: `https://wa.me/${phoneNumber}?text=${message}`,
    });
  }

  created() {
    if (this.$IS_PWA) {
      this.$router.replace("/");
    }
  }
}
</script>
