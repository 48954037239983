<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        v-if="!statusConnection"
        class="fixed left-0 right-0 top-5 z-20 m-auto  flex w-4/5 justify-center rounded-md bg-primary py-3 text-grey shadow-sm"
      >
        <div class="flex">
          <div class="mr-3 animate-pulse">
            <svg
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8582 14.273L20.292 21.7071C20.6825 22.0976 21.3157 22.0976 21.7062 21.7071C22.0967 21.3166 22.0966 20.6834 21.7061 20.2929L3.70699 2.29289C3.31648 1.90237 2.68336 1.90237 2.29287 2.2929C1.90237 2.68343 1.90238 3.31659 2.29288 3.70711L5.38952 6.80389C4.74669 7.23291 4.13637 7.73111 3.56902 8.2985C3.08004 8.78751 2.60757 9.36528 2.17959 9.98224C1.8648 10.436 1.97746 11.0591 2.43121 11.3739C2.88496 11.6887 3.50798 11.576 3.82276 11.1222C4.18618 10.5984 4.584 10.1119 4.98314 9.71271C5.55416 9.14165 6.17751 8.65481 6.83774 8.25218L7.99009 9.40459C7.3822 9.75406 6.80985 10.1886 6.29033 10.7081C5.67494 11.3236 5.17269 12.0188 4.78674 12.7819C4.53748 13.2747 4.7349 13.8763 5.2277 14.1256C5.72049 14.3748 6.32205 14.1774 6.57131 13.6846C6.862 13.1098 7.23942 12.5874 7.70445 12.1223C8.23236 11.5944 8.83264 11.1829 9.47334 10.8879L10.8808 12.2954C9.94817 12.5056 9.06191 12.9736 8.33591 13.6997C7.87583 14.1598 7.50976 14.709 7.24574 15.3124C7.02437 15.8184 7.25505 16.408 7.76098 16.6294C8.26692 16.8508 8.85652 16.6201 9.0779 16.1141C9.24476 15.7328 9.4718 15.3921 9.75003 15.1139C10.5923 14.2716 11.7836 13.9913 12.8582 14.273ZM11.5261 8.34349L13.7542 10.5718C14.7119 10.8503 15.615 11.3672 16.3701 12.1223C16.8142 12.5664 17.2074 13.1175 17.5073 13.7037C17.7588 14.1954 18.3612 14.3902 18.8529 14.1387C19.3445 13.8872 19.5393 13.2847 19.2878 12.793C18.8955 12.026 18.3806 11.3045 17.7843 10.7081C16.0672 8.99095 13.773 8.20274 11.5261 8.34349ZM8.50978 5.3269L10.1612 6.97843C13.2687 6.39677 16.6007 7.30819 19.0051 9.71271C19.4255 10.1332 19.8273 10.6194 20.177 11.1261C20.4907 11.5806 21.1135 11.6948 21.568 11.3811C22.0225 11.0674 22.1366 10.4446 21.8229 9.99005C21.406 9.38596 20.9272 8.8065 20.4192 8.2985C17.196 5.07508 12.5856 4.08455 8.50978 5.3269ZM13.0619 16.441C13.6481 17.0272 13.6481 17.9777 13.0619 18.5638C12.4758 19.15 11.5254 19.15 10.9393 18.5638C10.3531 17.9777 10.3531 17.0272 10.9393 16.441C11.5254 15.8549 12.4758 15.8549 13.0619 16.441Z"
                fill="#4D5458"
              />
            </svg>
          </div>
          <div>Соединение утрачено</div>
        </div>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div
        v-if="connectionBack"
        class="fixed left-0 right-0 top-5 z-20 m-auto  flex w-4/5 justify-center rounded-md bg-yellowLight py-3 text-grey shadow-sm"
      >
        <div class="flex">
          <div class="mr-3">
            <i class="fas fa-smile text-green" />
          </div>
          <div>Соединение восстановлено</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Network } from "@capacitor/network";
import { Getter, Mutation } from "s-vuex-class";

export default class NoInternet extends Vue {
  @Mutation("changeConnectionState") private changeConnectionState:
    | ((value: boolean) => void)
    | undefined;
  @Getter("getConnectionState") private statusConnection: boolean | undefined;

  private connectionBack = false;

  async watchConnection() {
    if (this.$PLATOFRM != "web") {
      Network.addListener("networkStatusChange", () => {
        setTimeout(async () => {
          const connection = await Network.getStatus();

          if (!connection.connected) {
            if (this.changeConnectionState) {
              this.changeConnectionState(false);
            }
          }

          if (connection.connected) {
            if (!this.statusConnection) {
              if (this.changeConnectionState) {
                this.changeConnectionState(true);
              }
              this.connectionBack = true;

              setTimeout(() => {
                this.connectionBack = false;
              }, 3000);
            }
          }
        }, 3000);
      });

    } else {

      const handleConnectionChange = () => {

        const online = navigator.onLine;

        if (!online) {
          // Offline
          updateNetworkStatus(false);

        } else {
          // Back online
          updateNetworkStatus(true);

          // Only trigger reconnection logic if
          // we've been offline for a bit
          if (!this.connectionBack) {
            this.connectionBack = true;

            setTimeout(() => {
              this.connectionBack = false;
            }, 3000);
          }
        }
      };
      // Listen for online/offline events
      window.addEventListener("online", handleConnectionChange);
      window.addEventListener("offline", handleConnectionChange);

      // Check connection on load
      handleConnectionChange();


      // Notify app of connection change
      const updateNetworkStatus = (isOnline: boolean) => {
        if (this.changeConnectionState) {
          this.changeConnectionState(isOnline);
        }
      };
    }
  }

  created() {
    this.watchConnection();
  }
}
</script>