<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class WikiComponent extends Vue {
  @Prop({ default: "https://help.force2.ru" }) public link!: string;
  @Prop({ default: "Возникли трудности?" }) public title!: string;
  @Prop({ default: "Нажмите сюда и мы поможем" }) public subtitle!: string;

  public redirectToWiki() {
    window.open(this.link, "_blank");
  }
}
</script>

<template>
  <div @click="redirectToWiki" class="rounded-lg bg-greyLight flex space-x-3 items-center py-2 px-3 mt-6">
    <div class="text-3xl text-blue">
      <i class="fas fa-question-circle"></i>
    </div>
    <div>
      <p class="font-medium">{{ title }}</p>
      <p class="text-sm">{{ subtitle }}</p>
    </div>
  </div>
</template>

<style scoped>

</style>