<template>
  <div v-auto-animate>
    <div
      v-if="!isTelegramConnect"
      class="p-3 flex bg-[#FFDBD766] flex-col items-center text-center rounded-lg border-[#FFB9B6] border-2"
    >
      <div>
        Подключите Telegram, чтобы
        <span class="text-blue underline" @click="openVideo">обезопасить</span>
        свои деньги
      </div>
      <button-component
        @clickAction="openTelegram"
        title="Установить"
        rounded="lg"
        height="10"
        class="mt-2"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ApiHome from "@/api/ApiHome";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import * as Sentry from "@sentry/vue";

@Options({
  components: { ButtonComponent },
  name: "TelegramBanner",
})
export default class TelegramBanner extends Vue {
  private isTelegramConnect = true;

  private async getTelegramConnect() {
    this.isTelegramConnect = await ApiHome.getTelegramConnect();
  }

  private openTelegram() {
    window.open(process.env.VUE_APP_TELEGRAM_URL, "_blank");

    Sentry.captureMessage("Redirect to connect Telegram");

    this.isTelegramConnect = true;
  }

  private openVideo() {
    window.open("https://www.youtube.com/watch?v=p0A_h4sbmaU");
  }

  activated() {
    this.getTelegramConnect();
  }
}
</script>
