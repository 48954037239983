<template>
  <div class="bg-white p-[20px] font-sans">
    <section class="flex">
      <div class="mr-5 h-[56px] w-[56px]">
        <img
          src="@/assets/img/store/app-icon.png"
          class="rounded-sm"
          alt="icon"
        />
      </div>
      <div>
        <div class="text-[22px] text-black text-opacity-80">ФорсМани Pro</div>
        <div class="text-[14px] font-bold text-[#01875F]">
          LLC Force Technologies
        </div>
      </div>
    </section>

    <section class="mt-10 flex w-full">

      <div
        class="flex flex-auto flex-col items-center border-r border-gray-400"
      >
        <div class="flex items-center text-black">
          <div class="mr-1 font-bold text-opacity-80">4.7</div>
          <div class="h-[16px] w-[16px]">
            <img src="@/assets/img/store/rating.svg" alt="rating" />
          </div>
        </div>
        <div class="text-[12px] text-black text-opacity-60">10 отзывов</div>
      </div>
      <div
        class="flex flex-auto flex-col items-center border-r border-gray-400"
      >
        <div class="font-bold text-black text-opacity-80">100 тыс+</div>
        <div class="text-[12px] text-black text-opacity-60">Скачивания</div>
      </div>
      <div class="flex flex-auto flex-col items-center justify-center">
        <div class="h-[18px] w-[18px]">
          <img src="@/assets/img/store/download.svg" alt="rating" />
        </div>
        <div class="mt-2 text-[12px] text-black text-opacity-60">6.7МБ</div>
      </div>
    </section>

    <div
      @click="sendPromt"
      id="storeBtn"
      class="mt-7 flex justify-center rounded-lg bg-[#01875F] p-[8px] font-sans font-medium text-white"
    >
      Установить
    </div>

    <section
      class="hide-scrollbar mt-6 flex flex-nowrap overflow-x-auto whitespace-nowrap"
    >
      <div v-for="index in 5" :key="index" class="mr-3 w-[60px] min-w-[120px]">
        <img
          class="rounded-sm"
          :src="require(`@/assets/img/store/mockups/slide${index}.png`)"
          alt=" image"
        />
      </div>
    </section>

    <div class="mt-6">
      <div class="text-lg text-black text-opacity-80">Описание</div>
      <div class="mt-2 text-sm text-black text-opacity-70">
        Моментальные выплаты за заказы Яндекс.Такси, Яндекс.Доставка. Сити
        мобил.
      </div>
      <div class="mt-4 flex">
        <div
          class="mr-3 rounded-full border border-gray-400 py-1 px-4 text-black text-opacity-80"
        >
          Автомобили
        </div>
        <div
          class="rounded-full border border-gray-400 py-1 px-4 text-black text-opacity-80"
        >
          Бизнес
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="text-lg text-black text-opacity-80">Оценки и отзывы</div>
      <div class="mt-5 flex">
        <section>
          <div class="text-6xl text-black text-opacity-80">4.0</div>
          <div class="flex">
            <template v-for="index in 5" :key="index + 100">
              <div class="mr-[2px] mt-3 fill-[#01875F]">
                <svg
                  v-if="index != 5"
                  xmlns="http://www.w3.org/2000/svg"
                  height="13"
                  viewBox="0 96 960 960"
                  width="13"
                >
                  <path
                    d="m196 1028 75-324L19 486l332-28 129-306 129 306 332 28-252 218 76 324-285-173-284 173Z"
                  />
                </svg>

                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  height="13"
                  viewBox="0 96 960 960"
                  width="13"
                >
                  <path
                    d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z"
                  />
                </svg>
              </div>
            </template>
          </div>
          <div class="mt-2 text-xs text-black text-opacity-70">1,255</div>
        </section>

        <section class="ml-10 flex w-full flex-col">
          <div class="flex items-center">
            <div class="mr-2 text-black text-opacity-80">5</div>
            <div class="h-[8px] w-full rounded-full bg-[#DBDBDB]">
              <div class="h-full w-4/5 rounded-full bg-[#01875F]" />
            </div>
          </div>
          <div class="flex items-center">
            <div class="mr-2 text-black text-opacity-80">4</div>
            <div class="h-[8px] w-full rounded-full bg-[#DBDBDB]">
              <div class="h-full w-1/3 rounded-full bg-[#01875F]" />
            </div>
          </div>
          <div class="flex items-center">
            <div class="mr-2 text-black text-opacity-80">3</div>
            <div class="h-[8px] w-full rounded-full bg-[#DBDBDB]">
              <div class="h-full w-3/12 rounded-full bg-[#01875F]" />
            </div>
          </div>
          <div class="flex items-center">
            <div class="mr-2 text-black text-opacity-80">2</div>
            <div class="h-[8px] w-full rounded-full bg-[#DBDBDB]">
              <div class="h-full w-2/12 rounded-full bg-[#01875F]" />
            </div>
          </div>
          <div class="flex items-center">
            <div class="mr-2 text-black text-opacity-80">1</div>
            <div class="h-[8px] w-full rounded-full bg-[#DBDBDB]">
              <div class="h-full w-1/12 rounded-full bg-[#01875F]" />
            </div>
          </div>
        </section>
      </div>
    </div>
    <StatusModal
      :state="modalState"
      type="warning"
      button-text="Прочитал"
      @confirm="modalState = false"
    >
      <template v-slot:description>
        <div v-if="isInstalled" class="font-semibold">
          У вас уже установлено приложение. Найдите его в общем списке
          приложений
        </div>
        <div v-else>
          <div class="font-semibold">
            Установка приложения временно не возможна в этом браузере. Установите приложение Google Play
          </div>
          <div class="underline mt-1 font-bold">
            <a
              class="text-blue"
              href="https://play.google.com/store/apps/details?id=money.force.driverapp"
              target="_blank"
            >Открыть Google Play</a
            >
          </div>
        </div>
      </template>
    </StatusModal>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Getter, Mutation } from "s-vuex-class";

import StatusModal from "@/components/Modals/StatusModal.vue";
import UtilStorage from "@/util/Common/UtilStorage";
import * as Sentry from "@sentry/vue";
import { UAParser } from "ua-parser-js";

@Options({
  components: { StatusModal },
})
export default class StoreApp extends Vue {
  @Getter("getDeferredPromo") private deferredPrompt: any;
  @Mutation("setDeferredPrompt") private setDeferredPrompt:
    | ((value: any) => void)
    | undefined;
  public modalState = false;
  public isInstalled = false;

  private currentBrowser = "";

  async sendPromt() {
    // When the user clicks the button, show the installation prompt

    if (this.currentBrowser.includes("Yandex")) {
      this.modalState = true;
      return;
    }

    let listOfInstalledApps;
    if ("getInstalledRelatedApps" in navigator) {
      listOfInstalledApps = await (navigator as any)?.getInstalledRelatedApps();
    }

    const isInstalledStore = await UtilStorage.getItem("appInstalled");

    if (isInstalledStore || listOfInstalledApps?.length > 0) this.isInstalled = true;


    if (this.deferredPrompt) {
      // Show the installation prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt

      const { outcome } = await this.deferredPrompt.userChoice;

      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
        Sentry.captureMessage("Success install online app Android");
        await UtilStorage.setItem("appInstalled", "true");
        this.isInstalled = true;
        this.$router.replace("/success-install");
      } else {
        console.log("User dismissed the install prompt");
      }

      // Clear the deferredPrompt variable since it can only be used once
      this.setDeferredPrompt?.(null);
    } else {
      this.modalState = true;
    }
  }


  mounted() {
    this.currentBrowser = new UAParser(navigator.userAgent).getBrowser().name as string;
    if (this.$IS_PWA) {
      this.$router.replace("/");
    }
    this.addRippleEffect();
  }

  private addRippleEffect() {
    const button: HTMLElement = document.getElementById(
      "storeBtn",
    ) as HTMLElement;

    button.addEventListener("click", (e: MouseEvent) => {
      let x = e.clientX - (e.target as HTMLInputElement).offsetLeft;
      let ripples = document.createElement("span");
      ripples.style.left = x + "px";
      ripples.className = "animate-btn";
      button.appendChild(ripples);
      setTimeout(() => {
        ripples.remove();
      }, 1000);
    });
  }
}
</script>
<style lang="less">
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>
