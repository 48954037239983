<template>
  <div class="w-full animate-pulse">
    <div class="h-4 w-1/2 rounded-md bg-gray-200" />
    <div class="mt-3 h-28 w-full rounded-md bg-gray-200" />
    <div class="mt-4">
      <div class="h-4 w-1/2 rounded-md bg-gray-200" />
      <div class="mt-3 h-12 w-full rounded-md bg-gray-200" />
    </div>
    <div class="mt-4">
      <div class="h-4 w-4/5 rounded-md bg-gray-200" />
      <div class="mt-2 h-4 w-1/2 rounded-md bg-gray-200" />
      <div class="mt-2 h-4 w-1/2 rounded-md bg-gray-200" />
    </div>
    <div class="mt-4">
      <div class="h-10 w-full rounded-lg bg-gray-200" />
      <div class="mt-2 h-10 w-full rounded-lg bg-gray-200" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class EditCardSkeleton extends Vue {}
</script>
