<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({})
export default class NewCardIcon extends Vue {

}
</script>

<template>
  <svg
    width="111"
    height="96"
    class="absolute bottom-0 right-0 rounded-br-md"
    viewBox="0 0 111 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M111 21.3534C99.3684 8.25357 82.3993 0 63.5 0C28.4262 0 0 28.4262 0 63.5C0 75.3776 3.25996 86.4929 8.93407 96H111V21.3534ZM57.5469 85.3281V69.4531H41.6719C38.3729 69.4531 35.7188 66.799 35.7188 63.5C35.7188 60.201 38.3729 57.5469 41.6719 57.5469H57.5469V41.6719C57.5469 38.3729 60.201 35.7188 63.5 35.7188C66.799 35.7188 69.4531 38.3729 69.4531 41.6719V57.5469H85.3281C88.6272 57.5469 91.2812 60.201 91.2812 63.5C91.2812 66.799 88.6272 69.4531 85.3281 69.4531H69.4531V85.3281C69.4531 88.6272 66.799 91.2812 63.5 91.2812C60.201 91.2812 57.5469 88.6272 57.5469 85.3281Z"
      fill="#5E79FF"
    />
  </svg>
</template>
