import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "form-title" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "form-subtitle mt-2"
}
const _hoisted_4 = {
  key: 3,
  class: "mt-2 text-[18px] font-normal text-grey"
}
const _hoisted_5 = {
  key: 0,
  class: "attention-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (Array.isArray(_ctx.title))
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.title, (item) => {
            return (_openBlock(), _createElementBlock("div", { key: item }, _toDisplayString(item), 1))
          }), 128))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1)),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true),
      (_ctx.phone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " +" + _toDisplayString(_ctx.formatPhone()), 1))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "action"),
    (_ctx.attention)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.attention), 1))
      : _createCommentVNode("", true)
  ]))
}