<template>
  <div v-auto-animate>
    <div
      v-if="userData"
      class="mb-5 flex justify-between  text-grey items-center"
    >
      <!--      <div id="userFullName">-->
      <!--        {{-->
      <!--          `${userData.first_name} ${userData.middle_name} ${userData.last_name}`-->
      <!--        }}-->
      <!--      </div>-->
      <!--      <div class="flex space-x-3 text-sm">-->
      <!--        <div id="userPhone">{{ userData.phone_number }}</div>-->
      <!--        <div v-if="appVersion" class="text-coolGray-400">-->
      <!--          {{ appVersion }}-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="flex">
        <div
          class="h-12 w-12 rounded-full flex justify-center items-center border-2 bg-greyLight text-grey text-xl">
          {{ userData.full_name ? userData.full_name.charAt(0) : "" }}
          <!--          <i class="fas fa-crown" />-->
        </div>
        <div class="ml-4">
          <div class="font-medium flex space-x-2 items-center">
            <p data-cy="userName">{{ userData.full_name }}</p>
            <!--            <i class="fas fa-chevron-right"></i>-->
          </div>
          <p data-cy="userPhone">{{ userData.phone_number }}</p>
        </div>
      </div>
    </div>
    <user-info-skeleton v-else />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import UserInfoSkeleton from "@/components/Skeleton/Home/UserInfoSkeleton.vue";
import { Action, Getter } from "s-vuex-class";
import { IUserData } from "@/struct/IStore/IStore";
import { App } from "@capacitor/app";

@Options({
  components: { UserInfoSkeleton },
})
export default class UserInfoComponent extends Vue {
  @Action("getUserData") private getUserData: (() => void) | undefined;

  @Getter("getUserData") private userData: IUserData | undefined;

  public appVersion: string | null = null;

  async created() {
    if (!this.userData) {
      this.getUserData?.();
    }

    if (this.$PLATFORM !== "web") {
      const appInfo = await App.getInfo();
      this.appVersion = `v${appInfo.version} ${appInfo.build}`;
    }
  }
}
</script>
