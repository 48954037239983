<template>
  <div class="w-full h-[395px] relative">
    <div class="animate-pulse">
      <div class="flex space-x-2 items-center my-4">
        <div class="w-full rounded-sm h-[35px] bg-gray-200" />
        <div class="w-full rounded-sm h-[35px] bg-gray-200" />
      </div>
      <div class="mb-4 mt-3 h-32 rounded-md bg-gray-200 flex justify-center items-center">
        <span class="text-xl text-gray-800 font-medium">Баланс загружается</span>
      </div>
      <div class="mt-3 h-32 rounded-md bg-gray-200" />
      <div class="mt-3 h-10 w-full rounded-lg bg-gray-200" />

    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class MainPageSkeleton extends Vue {
}
</script>
