import $api from "@/api/ApiInteceptor";
import { IUserData } from "@/struct/IStore/IStore";
import { EWebMethod } from "@/struct/IRequest/IRequest";
import { IAppVersion } from "@/struct/IAppVersion/IAppVersion";
import { IAuthLoginResponse, IAuthResponse } from "@/struct/ILogin/ILogin";
import axios from "axios";

export default class ApiEnter {
  public static async getAccountUser(): Promise<IUserData | boolean> {
    try {
      const response = await $api.call(
        EWebMethod.GET,
        "/api/v4/mobile/driver/profile",
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }

  public static async getAppVersion(): Promise<IAppVersion> {
    const response = await $api.call(
      EWebMethod.GET,
      `/auth/user/check-version`,
    );
    return response?.data;
  }

  public static async getYandexCode(
    code: string,
  ): Promise<IAuthResponse | boolean> {
    try {
      const response = await $api.call(
        EWebMethod.GET,
        `/auth/user/login/yandex-auth/callback/?code=${code}`,
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }

  public static async activateWithYandex(
    code: string,
    username: string,
    password: string,
  ): Promise<IAuthResponse | boolean> {
    try {
      const response = await $api.call(
        EWebMethod.POST,
        "/auth/user/login/confirmed/yandex-activate",
        {
          code: code,
          username: username,
          password: password,
        },
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }

  public static async validateEnter(phone_number: string) {
    try {
      const response = await $api.call(
        EWebMethod.GET,
        `/auth/is-activate?username=${phone_number}`,
      );
      return response.data;
    } catch {
      return false;
    }
  }

  public static async activateUser(
    phone: string,
    password: string,
  ): Promise<{
    detail: string;
    otp_status: string;
    message: string;
  }> {
    const response = await $api.call(EWebMethod.POST, "/auth/activate", {
      username: phone,
      password: password,
    });
    return response?.data;
  }

  public static async loginUser(
    phone: string,
    password: string,
  ): Promise<IAuthLoginResponse> {
    const response = await $api.call(
      EWebMethod.POST,
      `/auth/user/login-mobile`,
      {
        username: phone,
        password: password,
      },
    );
    return response?.data;
  }

  public static async logout(): Promise<boolean> {
    try {
      await $api.call(EWebMethod.POST, "/auth/user/logout");
      return true;
    } catch {
      return false;
    }
  }

  public static async sendCode(otp: string) {
    const response = await $api.call(
      EWebMethod.POST,
      "/auth/user/login/confirmed",
      {
        code: otp,
      },
    );
    return response.data;
  }

  public static async sendCodeActivation(otp: string) {
    const response = await $api.call(EWebMethod.POST, "/auth/confirmed", {
      code: otp,
    });
    return response.data;
  }
}
