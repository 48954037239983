<template>
  <div class="bg-background p-4 pb-28 text-grey">
    <slot />
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class StandartTemplate extends Vue {}
</script>
