<template>
  <standart-template>
    <div class="px-4 py-2"></div>
    <div class="font-medium">Частые вопросы</div>
    <div class="mt-4">
      <template v-for="(item, index) in faqTabs" :key="index">
        <div
          class="mb-3 flex flex-col rounded-md bg-greyLight px-6 py-4"
          @click="item.isOpen = !item.isOpen"
        >
          <div class="flex items-center justify-between">
            <div>{{ item.title }}</div>
            <div class="transition-all" :class="{ 'rotate-180': item.isOpen }">
              <i class="fas fa-chevron-down" />
            </div>
          </div>
          <div v-auto-animate="{ duration: 150 }">
            <div v-if="item.isOpen" class="mt-2 text-sm">{{ item.answer }}</div>
          </div>
        </div>
      </template>
    </div>
  </standart-template>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";

@Options({
  components: { StandartTemplate },
})
export default class SupportPage extends Vue {
  private faqTabs: { title: string; answer: string; isOpen: boolean }[] = [
    {
      title: "Как удалить карту",
      answer: "Достаточно нажать кнопку удалить и все",
      isOpen: false,
    },
    {
      title: "Почему не выводятся деньги",
      answer: "Достаточно нажать кнопку удалить и все",
      isOpen: false,
    },
    {
      title: "Как получить бонусы",
      answer: "Достаточно нажать кнопку удалить и все",
      isOpen: false,
    },
    {
      title: "Как добавить карту",
      answer: "Достаточно нажать кнопку удалить и все",
      isOpen: false,
    },
    {
      title: "Как включить уведомления",
      answer: "Достаточно нажать кнопку удалить и все",
      isOpen: false,
    },
  ];
}
</script>
