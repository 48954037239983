export interface INotification {
  partner_bank_name: string;
  id: string;
  amount: number;
  date_time: string;
  status: ENotificationStatus;
  card_number: string;
  commission_withheld: number;
  status_description: string;
  status_payout: string;
  adapter_status: ENotificationStatusAdapter;
  cabinet_driver_id: string;
  card_name: string;
  bank: string;
}

export interface INotificationsResponse {
  count: number;
  results: INotification[];
  limit: number;
  skip: number;
}

export enum ENotificationStatus {
  Paid = "PAID",
  Process = "IN_PROCESSING",
  Error = "ERROR",
  AllowTransaction = "ALLOW_TRANSACTION",
  BlockTransaction = "BLOCK_TRANSACTION",
}
export enum ENotificationStatusAdapter {
  Reverted = "REVERTED",
  Success = "SUCCESS",
  Failed = "FAILED",
  Waiting = "WAITING",
  Null = "NULL",
  HandCheck = "HAND_CHECK",
  Attention = "ATTENTION",
}
