<template>
  <div>
    <div class="form-title">
      <template v-if="Array.isArray(title)">
        <div v-for="item in title" :key="item">
          {{ item }}
        </div>
      </template>
      <div v-else>{{ title }}</div>
      <div class="form-subtitle mt-2" v-if="subtitle">{{ subtitle }}</div>
      <div class="mt-2 text-[18px] font-normal text-grey" v-if="phone">
        +{{ formatPhone() }}
      </div>
    </div>

    <slot name="action" />
    <div class="attention-text" v-if="attention">{{ attention }}</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class LoginStep extends Vue {
  @Prop({ default: [] }) private title!: string[] | string;
  @Prop({ default: "" }) private subtitle!: string;
  @Prop({ default: "" }) private phone!: string;
  @Prop({ default: "" }) private attention!: string | null;

  private formatPhone() {
    const formattedPhone = this.phone.replace(/\D/g, "").split("");
    formattedPhone.splice(1, 0, " ");
    formattedPhone.splice(5, 0, " ");
    formattedPhone.splice(9, 0, " ");
    formattedPhone.splice(12, 0, " ");
    return formattedPhone.join("");
  }
}
</script>
