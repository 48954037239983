import $api from "@/api/ApiInteceptor";
import { EWebMethod } from "@/struct/IRequest/IRequest";
import { ICard } from "@/struct/IHomePage/ICabinetBalance";

export default class ApiCard {
  public static async createCard(name: string, number: string) {
    try {
      const response = await $api.call(
        EWebMethod.POST,
        `/api/v4/mobile/driver/card/create`,
        {
          name: name,
          number: number,
        },
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }

  public static async deleteCard(uuid: string) {
    try {
      const response = await $api.call(
        EWebMethod.DELETE,
        `/api/v4/mobile/driver/card/${uuid}/delete`,
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }

  public static async getCards(): Promise<ICard[] | null> {
    try {
      const response = await $api.call(
        EWebMethod.GET,
        "/api/v4/mobile/driver/cards",
      );
      return response.data;
    } catch (e) {
      return null;
    }
  }

  public static async getSBPMembers() {
    try {
      const response = await $api.call(
        EWebMethod.GET,
        "/api/v4/mobile/driver/card/bank-members",
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }

  public static async createSBPCard(
    name: string,
    phone_number: string,
    bank_member: string,
  ) {
    try {
      const data = {
        bank_sbp: bank_member,
        number: phone_number,
        name: name,
      };

      const response = await $api.call(
        EWebMethod.POST,
        "/api/v4/mobile/driver/card/sbp-create",
        data,
      );
      return response.data;
    } catch (e) {
      return null;
    }
  }

  public static async GetCardByUUID(uuid: string) {
    try {
      const response = await $api.call(
        EWebMethod.GET,
        `/api/v4/mobile/driver/card/${uuid}/get`,
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }

  public static async updateCard(uuid: string, name: string) {
    try {
      const response = await $api.call(
        EWebMethod.PATCH,
        `/api/v4/mobile/driver/card/${uuid}/update`,
        { name: name },
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }

  public static async setMainCard(uuid: string) {
    try {
      const response = await $api.call(
        EWebMethod.PATCH,
        `/api/v4/mobile/driver/card/${uuid}/update-main`,
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }

  public static async codeConfirmation(code: string) {
    try {
      const response = await $api.call(
        EWebMethod.POST,
        "/api/v2/account/user/code-confirmation",
        {
          code: code,
        },
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }
}
