<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class SBPIcon extends Vue {
  @Prop() public color!: string;
}
</script>

<template>
  <svg
    width="94"
    height="111"
    viewBox="0 0 94 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.6699 63L15.435 53.5498V72.2683L31.5 63H31.6699ZM62.3696 80.87V99.5397L78.478 90.2465L62.3696 80.87ZM46.9346 0L94 26.9621V35.91H78.7496L62.3696 26.46V45.3602L78.7496 35.9102H94V44.7219L62.3696 62.9333V63H62.37L62.3696 63.0002V63.0993L94 81.3782V98.9016L72.9868 111H46.9346V71.8871L0 98.91V98.9098V27.0898L46.9346 54.1796V0Z"
      :fill="color"
    />
  </svg>
</template>