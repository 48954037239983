interface WindowWithWebKit extends Window {
  webkit?: {
    messageHandlers?: any;
  };
}

export function isIosWebview(win: WindowWithWebKit): boolean {
  return !!win.webkit?.messageHandlers;
}

export function isAndroidWebView(userAgent: string): boolean {
  // Check if the user agent contains the 'wv' keyword
  // which is generally used to identify WebView on Android
  return userAgent.includes("wv");
}
