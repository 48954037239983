import UtilError from "@/util/ValidateError/UtilError";
import { App } from "@capacitor/app";
import { Capacitor, CapacitorHttp, HttpResponse } from "@capacitor/core";
import { EWebMethod, IRequest } from "@/struct/IRequest/IRequest";
import UtilStorage from "@/util/Common/UtilStorage";
import * as Sentry from "@sentry/vue";
import { setNativeCookie } from "@/util/Common/UtilCookie";
import { Http } from "@capacitor-community/http";
import store from "@/store";

class $api {
  public static async call(
    method: EWebMethod,
    url: string,
    data?: any,
  ): Promise<any | HttpResponse> {
    let baseURL = "";
    const platform = Capacitor.getPlatform();
    if (process.env.NODE_ENV === "production") {
      baseURL = process.env.VUE_APP_BASE_URL;
    }
    const options: IRequest = {
      url: baseURL + url,
      headers: {
        "X-App-Version-id": store.state.currentAppVersion,
        "Content-Type": "application/json",
        "X-App-Type": "driver",
        "X-App-Platform": platform,
        "X-Timezone-Offset": new Date().getTimezoneOffset().toString(),
        Accept: "*/*",
        Connection: "keep-alive",
        Credentials: "include",
      },
    };
    if (data) {
      method === EWebMethod.GET
        ? (options.params = data)
        : (options.data = data);
    }

    try {
      options.headers["User-Agent"] = navigator.userAgent;
    } catch (e) {
      console.error("e to get userAgent", e);
      options.headers["User-Agent"] = "Error user agent";
    }

    options.headers.AppPlatform = platform;

    if (platform !== "web") {
      const appInfo = await App.getInfo();
      options.headers.AppBuild = appInfo.build;
    }

    const token = await UtilStorage.getItem("token");
    const visitorId = await UtilStorage.getItem("visitor_id");

    if (token) {
      options.headers["Authorization"] = `Bearer ${token}`;
    }

    if (visitorId) {
      options.headers["X-Visitor-Id"] = visitorId;
    }

    const response: HttpResponse = await CapacitorHttp.request({
      ...options,
      method: method,
    });

    if (
      response == undefined ||
      (response as unknown) == "undefined" ||
      response.status === 408
    ) {
      Sentry.captureMessage("408 time out with url " + url);
      return {
        data: {
          detail: "time_out",
        },
      };
    }

    if (response.status === 200 || response.status === 400) {
      return response;
    }

    const replacerFunc = () => {
      const visited = new WeakSet();
      return (key: any, value: object | null) => {
        if (typeof value === "object" && value !== null) {
          if (visited.has(value)) {
            return;
          }
          visited.add(value);
        }
        return value;
      };
    };

    const responseServer = response.data
      ? JSON.stringify(response.data, replacerFunc())
      : null;

    const exceptionId =
      response?.data && response?.data?.detail && response?.data?.detail?.id
        ? response.data.detail.id
        : null;

    const sendData = data ? JSON.stringify(data, replacerFunc()) : null;

    UtilError.validateError(response.status, url, response.data);

    if (response?.status != 401 && response?.status != 429) {
      Sentry.captureException(
        `Error with request. Url: ${url}. Status: ${response.status} Error id: ${exceptionId}`,
        {
          extra: {
            phone: await UtilStorage.getItem("phone"),
            base_url: process.env.VUE_APP_BASE_URL,
            responseServer: responseServer,
            frontData: sendData,
          },
        },
      );
    }

    return false;
  }
}

export default $api;
