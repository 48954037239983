<template>
  <div
    class="relative flex flex-col rounded-md py-2 px-4 overflow-hidden text-grey transition-colors duration-300 select-none min-h-[116px]"
    :style="`background: ${colorBody}`"
    :class="isActiveCard === false ? 'justify-center' : ''"
  >
    <div class="select-none">{{ title }}</div>
    <div v-if="balance == null" class="font-medium text-4xl">Синхр откл</div>
    <div v-if="disabled" class="mt-1 text-4xl font-medium">{{ balance }}</div>
    <div class="flex items-center" v-else>
      <div class="flex" v-if="isActiveCard">
        <div
          class="mr-2 mt-1.5 text-2xl text-blue"
          @click="$refs.balanceCard.focus()"
        >
          <i class="fas fa-pen" />
        </div>
        <input
          :value="balance"
          class="mt-1 w-full bg-transparent text-4xl font-medium text-grey"
          type="tel"
          @focusin="isFocus = true"
          @focus="$event.target.select()"
          @focusout="isFocus = false"
          maxlength="6"
          @input="$emit('update:modelValue', $event.target.value)"
          ref="balanceCard"
        />
      </div>
      <div
        class="mt-1 w-full mh-auto bg-transparent text-lg font-medium text-grey"
        v-else
      >
        Выплаты отключены
      </div>
    </div>
    <div class="mt-2 select-none">{{ subtitle }}</div>
    <svg
      class="absolute top-0 right-0 h-full transition-all select-none"
      viewBox="0 0 116 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :style="`fill: ${colorIcon}`"
        class="transition-colors duration-300"
        d="M10.9562 123V0H62.67C79.1898 0 92.1781 3.51429 101.635 10.5429C111.212 17.4543 116 27.7043 116 41.2929C116 54.7643 111.212 65.0143 101.635 72.0429C92.1781 78.9543 79.1898 82.41 62.67 82.41H23.8847L34.2993 71.8671V123H10.9562ZM34.2993 74.3271L23.8847 63.96H62.4905C72.0671 63.96 79.4292 62.0271 84.5766 58.1614C89.8438 54.1786 92.4774 48.6143 92.4774 41.4686C92.4774 34.3229 89.8438 28.8757 84.5766 25.1272C79.4292 21.2614 72.0671 19.3286 62.4905 19.3286H23.8847L34.2993 8.08287V74.3271ZM-7 104.726V92.4257H73.8029V104.726H-7Z"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator";
import { Vue } from "vue-class-component";

export default class BalanceCard extends Vue {
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) subtitle!: string;
  @Prop({ default: "" }) limit!: number;
  @Prop() public balance!: string;
  @Prop() public amount!: number;
  @Prop() public max_amount!: number;
  @Prop() public min_amount!: number;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isActiveCard!: boolean;

  private filterColor = "";
  private colorBody = "";
  private colorIcon = "";
  private isFocus = false;

  private validateColorBodyAndIcon(): void {
    if (!this.disabled) {
      this.colorBody = "#E5FFD9";
      this.colorIcon = "#43A047";
      return;
    }

    const balance = Number(this.balance);
    if (balance === 0) {
      this.colorBody = "#FFF7D2";
      this.colorIcon = "#FFD92F";
      return;
    }

    if (balance <= 500) {
      this.colorBody = "#FFF1B4";
      this.colorIcon = "#FFDE4C";
      return;
    }

    if (balance <= 1000) {
      this.colorBody = "#FFEC96";
      this.colorIcon = "#FFDE4C";
      return;
    }

    if (balance <= 1500) {
      this.colorBody = "#FFE46A";
      this.colorIcon = "#FFF1B4";
      return;
    }

    if (balance <= 3000) {
      this.colorBody = "#FFDE4C";
      this.colorIcon = "#FFF1B4";
      return;
    }

    if (balance > 3000) {
      this.colorBody = "#FFD92F";
      this.colorIcon = "#FFF1B4";
      return;
    }
  }

  created() {
    this.validateColorBodyAndIcon();
  }
}
</script>
