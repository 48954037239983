<template>
  <div class="flex items-center space-x-3 text-grey mb-6">
    <div
      class="flex h-10 w-10 items-center justify-center rounded-lg bg-yellowLight"
      @click="routeBack"
    >
      <i class="fas fa-arrow-left" />
    </div>
    <div class="text-base">{{ title }}</div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class PageHeader extends Vue {
  @Prop() title!: string;
  @Prop() backUrl!: string;

  public routeBack() {
    if (this.backUrl) {
      this.$router.push(this.backUrl);
      return;
    }

    this.$router.push("/home");
  }
}
</script>
