<template>
  <StandartTemplate>
    <div class="mb-4 flex justify-center">
      <img src="@/assets/img/logo.svg" alt="logo" />
    </div>
    <template v-if="userData">
      <div
        data-cy="profileData"
        class="rounded-md bg-greyLight p-5 text-sm leading-7 text-grey"
      >
        <div>
          ФИО: <span class="text-gray-500">{{ userData.full_name }}</span>
        </div>
        <div>
          Номер телефона:
          <span class="text-gray-500">{{ userData.phone_number }}</span>
        </div>
        <!--        <div>Дата рождения: <span class="text-gray-500">04.09.1980</span></div>-->
      </div>

      <!--      <div-->
      <!--        class="mt-4 rounded-md bg-greyLight p-5 text-sm font-medium leading-7 text-grey"-->
      <!--      >-->
      <!--        <div>Машина</div>-->
      <!--        <div class="flex">-->
      <!--          <div class="w-1/2">Марка: <span class="text-gray-500">BMW</span></div>-->
      <!--          <div>Модель: <span class="text-gray-500">X5</span></div>-->
      <!--        </div>-->
      <!--        <div class="flex">-->
      <!--          <div class="w-1/2">-->
      <!--            Цвет: <span class="text-gray-500">Черный</span>-->
      <!--          </div>-->
      <!--          <div>Год выпуска: <span class="text-gray-500">2010</span></div>-->
      <!--        </div>-->
      <!--        <div>VIN: <span class="text-gray-500">1KLBN52TWXM186109</span></div>-->
      <!--      </div>-->
    </template>
    <ProfilePageSkeleton v-else />
  </StandartTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import BlurComponent from "@/components/BlurComponent/BlurComponent.vue";
import { Action, Getter } from "s-vuex-class";
import { IUserData } from "@/struct/IStore/IStore";
import ProfilePageSkeleton from "@/components/Skeleton/ProfilePageSkeleton.vue";

@Options({
  components: { ProfilePageSkeleton, BlurComponent, StandartTemplate },
})
export default class ProfilePage extends Vue {
  @Getter("getUserData") private userData: IUserData | undefined;
  @Action("getUserData") private getUserData: (() => void) | undefined;

  created() {
    if (!this.userData) {
      this.getUserData?.();
    }
  }
}
</script>
