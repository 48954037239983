import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 2,
  class: "absolute bottom-3 ml-3 leading-4 text-sm text-white font-medium font-roboto tracking-normal whitespace-pre-line"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-28 h-28 rounded-lg relative p-[2px] border border-primary mr-2",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitClick && _ctx.emitClick(...args)))
  }, [
    (!_ctx.story.onlyBackground)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.story.previewImage,
          class: "w-full h-full rounded-lg object-cover"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([_ctx.story.previewColor, "w-full h-full rounded-lg"])
        }, null, 2)),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true)
  ]))
}