<template>
  <div class="w-full">
    <div class="animate-pulse">
      <div class="mt-4 h-4 w-full rounded-md bg-gray-200" />
      <div class="mx-auto mt-4 h-4 w-1/2 rounded-md bg-gray-200" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class UserInfoSkeleton extends Vue {}
</script>
