<template>
  <div
    class="relative z-[19]"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      style="backdrop-filter: blur(3px)"
      class="fixed top-0 right-0 left-0 bottom-0 bg-gray-400 bg-opacity-75 transition-opacity"
    />

    <div class="fixed top-0 right-0 left-0 bottom-0 z-10 overflow-y-hidden">
      <div
        id="dialog"
        class="bounce-modal-animation flex min-h-full items-center justify-center p-0 px-4 text-center"
      >
        <div
          class="rounded-md bg-background p-5 text-base font-semibold text-grey text-grey"
        >
          <div class="mb-4 flex justify-center">
            <img src="@/assets/img/logo.svg" />
          </div>
          <div>Страница находится в разработке</div>
          <div>совсем скоро она будет готова</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class BlurComponent extends Vue {
}
</script>
