<template>
  <div class="flex">
    <input
      id="link-checkbox"
      type="checkbox"
      :value="model"
      class="h-8 w-8 rounded-[3px] border border-gray-300 accent-blue"
      @change="onCheckboxChange"
      :disabled="disabled"
    />
    <label for="link-checkbox" class="ml-4 text-sm text-grey">
      {{ label }}
      <slot v-if="!label" name="label" />
    </label>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class CheckboxComponent extends Vue {
  @Prop({ default: false }) model!: boolean;
  @Prop() label!: string;
  @Prop({ default: false }) disabled!: boolean;

  private onCheckboxChange(e: Event) {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      this.$emit("update", true);
    } else {
      this.$emit("update", false);
    }
  }
}
</script>
