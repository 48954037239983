<template>
  <standart-template class="h-full">
    <router-link
      v-for="item in tabs"
      :key="item.title"
      :to="item.route"
      class="mb-3 flex h-[116px] items-center justify-between rounded-md bg-greyLight px-4 py-2 text-grey"
    >
      <div>{{ item.title }}</div>
      <div class="pr-4">
        <i class="fas fa-chevron-right" />
      </div>
    </router-link>
    <div
      @click="redirectToWiki"
      class="mb-3 flex h-[116px] cursor-pointer items-center justify-between rounded-md bg-greyLight px-4 py-2 text-grey"
    >
      <div>Помощь с приложением</div>
      <div class="pr-4">
        <i class="fas fa-chevron-right" />
      </div>
    </div>
    <div
      @click="logout"
      class="mb-3 flex h-[116px] items-center justify-between rounded-md bg-greyLight px-4 py-2 text-red"
    >
      <div>Выход</div>
      <div class="pr-4">
        <i class="fas fa-chevron-right" />
      </div>
    </div>

    <div
      @click="confirmModalDialog = true"
      class="flex h-[116px] items-center justify-between rounded-md bg-greyLight px-4 py-2 text-red"
    >
      <div>Удалить профиль</div>
      <div class="pr-4">
        <i class="fas fa-chevron-right" />
      </div>
    </div>

    <button
      v-if="checkVersion() && (platform === 'android' || platform === 'ios')"
      class="absolute py-5 bottom-[100px] left-[50%] translate-x-[-50%] w-[90%] my-[8px] text-center bg-[#ffd930] text-sm rounded-xl leading-5"
      @click="openNewVersionModal()"
    >
      Обновить приложение
    </button>

    <confirm-modal
      :state="confirmModalDialog"
      confirm-button-text="Да"
      :description="'Вы действительно хотите удалить аккаунт?'"
      decline-button-text="Нет"
      @decline="confirmModalDialog = false"
      @confirm="
        statusModalDialog = true;
        confirmModalDialog = false;
      "
    />

    <status-modal
      type="success"
      description="Заявка на удаление передана партнеру!"
      :state="statusModalDialog"
      button-text="Хорошо"
      @confirm="logout()"
    />
  </standart-template>
</template>

<script lang="ts">
import ApiEnter from "@/api/ApiEnter";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import PageHeader from "@/components/Common/PageHeader.vue";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import StatusModal from "@/components/Modals/StatusModal.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import { IAppVersion } from "@/struct/IAppVersion/IAppVersion";
import UtilStorage from "@/util/Common/UtilStorage";
import { Http } from "@capacitor-community/http";
import { Capacitor } from "@capacitor/core";
import { Action, Getter } from "s-vuex-class";
import { Options, Vue } from "vue-class-component";
import store from "../../store";

@Options({
  components: {
    PageHeader,
    ButtonComponent,
    StandartTemplate,
    ConfirmModal,
    StatusModal,
  },
})
export default class SettingsPage extends Vue {
  @Action("logout") private clearStore: (() => void) | undefined;
  @Getter("getAppVersion") private appVersion!: IAppVersion;
  // @Getter("getCurrentAppVersion")
  private currentAppVersion!: string;
  private confirmModalDialog = false;
  private statusModalDialog = false;
  public platform = Capacitor.getPlatform();

  public checkVersion() {
    if (this.appVersion?.detail != "VERSION_SUCCESSES") {
      return true;
    } else {
      return false;
    }
  }

  public openNewVersionModal() {
    store.state.isOpenNewVersionModal = true;
  }

  public redirectToWiki() {
    window.open("https://help.force2.ru", "_blank");
  }

  private tabs: { title: string; route: string }[] = [
    // {
    //   title: "Контакты",
    //   route: "",
    // },
    // {
    //   title: "История входов",
    //   route: "login-history",
    // },
    // {
    //   title: "Восстановить пароль",
    //   route: "",
    // },
  ];

  private async logout() {
    this.statusModalDialog = false;
    UtilStorage.removeItem("phone");
    await ApiEnter.logout();
    this.clearStore?.();
    this.$router.replace("/");
    if (this.$PLATFORM != "web") {
      Http.clearCookies({
        url: process.env.VUE_APP_BASE_URL,
      });
    }
  }
}
</script>
