<template>
  <div v-auto-animate>
    <section v-show="showApp()">
      <div
        class="z-50 select-none fixed bottom-0 flex-col w-full bg-white border-t border-gray-200 flex shadow-md px-6 py-4 rounded-t-2xl overflow-hidden"
      >
        <div class="flex justify-between w-full items-baseline">
          <h2 class="text-lg font-medium mb-2">
            {{
              isInstalled ? "Приложение установлено" : "Установите приложение"
            }}

          </h2>
          <div @click="hideModal">
            <i class="fas fa-times"></i>
          </div>
        </div>

        <div class="p-4 bg-greyLight border border-gray-300 flex rounded">
          <div class="w-12 h-12 mr-5" v-if="!isInstalled">
            <img :src="require('/assets/logo.png')" alt="" />
          </div>
          <div
            class="w-12 h-12 mr-5 flex justify-center items-center bg-green text-white text-2xl rounded-full"
            v-else
          >
            <i class="fas fa-check"></i>
          </div>
          <div>
            <p>ФорсМани Pro</p>
            <p class="text-sm mt-1">{{ windowLocation }}</p>
          </div>
        </div>


        <section v-if="currentBrowser === 'Yandex'">
          <div class="mt-5">Установка на Android возможна только с браузера Google Chrome</div>
          <div class="mt-3 flex justify-center">
            <a
              class="text-white p-3 rounded-full bg-green"
              href="https://play.google.com/store/apps/details?id=com.android.chrome"
              target="_blank"
            >Открыть Google Chrome</a
            >
          </div>
        </section>

        <section v-else-if="isSafari" class="h-full flex flex-col mt-5">
          <div class="flex items-center">
            <div class="mr-3 font-medium">1.</div>
            <div>Нажать на</div>
            <div class="p-1 mx-1 bg-greyLight border border-gray-300 rounded">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 0 24 24"
                width="20"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  fill="#098df9"
                  d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z"
                />
              </svg>
            </div>
            <div>панели вкладок (снизу)</div>
          </div>
          <div class="flex mt-3">
            <div class="mr-3 font-medium">2.</div>
            <div>
              <div>Прокрутите и выберите</div>
              <div
                class="p-1 w-max mt-1 bg-greyLight border border-gray-300 rounded font-medium"
              >
                на экран «Домой»
              </div>
            </div>
          </div>
          <div class="mt-3 flex">
            <div class="mr-3 font-medium">3.</div>
            <div>
              <div class="flex">
                <div class="mr-3">Откройте приложение</div>
                <div class="shadow-md w-6 h-6">
                  <img class="" :src="require('/assets/logo.png')" alt="" />
                </div>
              </div>
              <div>с главного экрана</div>
            </div>
          </div>
          <div class="mt-3">
            <div class="flex justify-center font-medium">Нужна помощь?</div>
            <div
              @click="openWhatsApp('IOS')"
              class="mt-2 flex flex-col items-center rounded-full bg-[#4593FC] px-4 py-1 text-sm font-medium text-white"
            >
              <div class="flex items-center">
                <div class="text-2xl">
                  <i class="fab fa-whatsapp" />
                </div>
                <div class="ml-2 text-base">Написать Whatsapp</div>
              </div>
            </div>
          </div>
        </section>

        <section v-else>
          <div
            v-if="!isInstalled && deferredPrompt"
            @click="sendPrompt"
            class="p-4 bg-greyLight border-dashed border-2 border-gray-300 flex rounded h-48 mt-2 justify-center items-center flex-col"
          >
            <div class="fill-grey">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 96 960 960"
                width="24"
              >
                <path
                  d="M790 976 644 830l42-43 74 74V680h60v181l74-74 42 43-146 146Zm-650 0q-24 0-42-18t-18-42V236q0-24 18-42t42-18h340l240 240v209H600v351H140Zm300-520h220L440 236v220ZM200 856h400q-4-49-30-90t-68-65l38-68q2-4 1-9t-6-7q-4-2-8.5-1t-6.5 5l-39 70q-20-8-40-12.5t-41-4.5q-21 0-41 4.5T319 691l-39-70q-2-5-6.5-5t-9.5 2l-4 15 38 68q-42 24-68 65t-30 90Zm110-60q-8 0-14-6t-6-14q0-8 6-14t14-6q8 0 14 6t6 14q0 8-6 14t-14 6Zm180 0q-8 0-14-6t-6-14q0-8 6-14t14-6q8 0 14 6t6 14q0 8-6 14t-14 6Z"
                />
              </svg>
            </div>
            <div class="mt-1">Нажмите, чтобы установить</div>
          </div>
          <!--          Установка на Android при невозможной автоматической установки -->
          <template v-else>
            <div class="flex items-center mt-4">
              <div class="mr-3 font-medium">1.</div>
              <div>Нажать на</div>
              <div class="px-1 py-px mx-1 bg-greyLight border border-gray-300 rounded">
                <i class="fas fa-ellipsis-v" />
              </div>
              <p>панели вкладок </p>
            </div>
            <div class="mt-1">(справа верху)</div>
            <div class="flex mt-3">
              <div class="mr-3 font-medium">2.</div>
              <div>
                <div>Прокрутите и выберите</div>
                <div
                  class="p-1 w-max mt-1 bg-greyLight border border-gray-300 rounded items-center flex space-x-2"
                >
                  <img src="@/assets/img/general/install-app.svg" class="fill-gray-700" alt="">
                  <p class="font-medium">Установить приложение</p>
                </div>
              </div>
            </div>
            <div class="mt-3 flex">
              <div class="mr-3 font-medium">3.</div>
              <div>
                <div class="flex">
                  <div class="mr-3">Откройте приложение</div>
                  <div class="shadow-md w-6 h-6">
                    <img class="" :src="require('/assets/logo.png')" alt="" />
                  </div>
                </div>
                <div>с главного экрана</div>
              </div>
            </div>
            <div class="mt-3">
              <div class="flex justify-center font-medium">Нужна помощь?</div>
              <div
                @click="openWhatsApp('Android')"
                class="mt-2 flex flex-col items-center rounded-full bg-[#4593FC] px-4 py-1 text-sm font-medium text-white"
              >
                <div class="flex items-center">
                  <div class="text-2xl">
                    <i class="fab fa-whatsapp" />
                  </div>
                  <div class="ml-2 text-base">Написать Whatsapp</div>
                </div>
              </div>
            </div>
          </template>


        </section>

        <div
          class="items-center mt-10 flex flex-col text-xl"
          v-if="isInstalled"
        >
          <div>Откройте приложение</div>
          <div class="shadow-md w-12 h-12 my-3">
            <img class="" :src="require('/assets/logo.png')" alt="" />
          </div>
          <div>с главного экрана</div>
        </div>
      </div>
      <div
        class="fixed top-0 left-0 w-full h-full bg-gray-500 opacity-50 z-40"
      />
    </section>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Getter, Mutation } from "s-vuex-class";
import UtilStorage from "@/util/Common/UtilStorage";
import * as Sentry from "@sentry/vue";
import { Browser } from "@capacitor/browser";

export default class InstallModal extends Vue {
  @Getter("getDeferredPromo") private deferredPrompt: any;
  @Mutation("setDeferredPrompt") private setDeferredPrompt:
    | ((value: any) => void)
    | undefined;

  private windowLocation: Location | string = "";
  private isInstalled = false;
  private isSafari = false;
  public currentBrowser = "";

  private showApp() {
    if (this.$route.query["show-app"] == "show") {
      document.body.classList.add("overflow-hidden");
    } else {
      if (this.$route.query["show-app"] == "show") {
        document.body.classList.remove("overflow-hidden");
      }
    }
    return this.$route.query["show-app"] == "show";
  }

  private hideModal() {
    this.$router.replace({
      query: { "show-app": "hide" },
    });
    document.body.classList.remove("overflow-hidden");
  }

  async sendPrompt() {
    // When the user clicks the button, show the installation prompt

    if (this.deferredPrompt) {
      // Show the installation prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt

      const { outcome } = await this.deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
        await UtilStorage.setItem("appInstalled", "true");
        Sentry.captureMessage("Success install online app Android");
        this.isInstalled = true;
      } else {
        console.log("User dismissed the install prompt");
      }
      // Clear the deferredPrompt variable since it can only be used once
      this.setDeferredPrompt?.(null);
    }
  }

  public openWhatsApp(type: "Android" | "IOS") {
    const phoneNumber = encodeURI(process.env.VUE_APP_WHATSAPP_NUMBER);
    let message = `Проблема с установкой приложения через онлайн магазин. ${type}. `;
    message = encodeURI(message);
    Browser.open({
      url: `https://wa.me/${phoneNumber}?text=${message}`});
  }

  async created() {
    this.currentBrowser = this.$BROWSER_NAME;
    this.isSafari = this.$IS_SAFARI;
    this.windowLocation = window.location.hostname;

    const isInstalled = await UtilStorage.getItem("appInstalled");
    if (isInstalled) this.isInstalled = true;
    this.showApp();
  }
}
</script>
