<template>
  <Transition name="modal" :duration="600">
    <div
      class="fixed z-50"
      aria-labelledby="modal-title"
      role="dialog"
      v-show="visible"
      aria-modal="true"
    >
      <div class="fixed top-0 right-0 left-0 bottom-0 bg-gray-400 bg-opacity-75 transition-opacity" />

      <div @click="onClickOutside" class="fixed top-0 right-0 left-0 bottom-0 z-10 overflow-y-hidden">
        <div
          id="dialog"
          class="bounce-modal-animation flex min-h-full items-center justify-center p-0 px-4 text-center"
        >
          <div
            @click.stop
            class="relative h-min w-full rounded-lg bg-background p-8 shadow-xl"
          >
            <!--              Icons-->
            <div
              class="absolute top-[-80px] right-0 left-0 z-20 flex flex-col items-center justify-center rounded-full"
            >
              <svg
                fill="white"
                class="h-[150px] w-[150px]"
                stroke="currentColor"
                viewBox="0 0 24 24"
                :color="type === 'error' ? '#FF5252' : '#FFD92F'"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  :color="type === 'error' ? '#FF5252' : '#FFD92F'"
                  fill-rule="nonzero"
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <!--              Content-->
            <div class="mt-16 flex flex-col items-center text-grey">
              <div
                class="whitespace-pre-line font-semibold"
                v-html="errorMessage"
              />
              <div
                class="mt-3 flex items-center justify-center text-xs"
                v-if="appVersion"
              >
                {{ appVersion }}
              </div>

              <button-component
                @clickAction="visible = false"
                :full-width="false"
                height="10"
                class="mt-5"
                input-class="px-12"
                rounded="lg"
                title="Прочитал"
                :color="type === 'error' ? 'red' : '[#FFD92F]'"
                text-color="white"
              />
            </div>
            <!--            Footer-->
            <div
              class="absolute bottom-[-10px] left-0 right-0 z-[-1] ml-auto mr-auto h-12 w-11/12 rounded-lg"
              :class="type === 'error' ? 'bg-red' : 'bg-[#FFD92F]'"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import { App } from "@capacitor/app";

@Options({
  components: { ButtonComponent },
  watch: {
    state(value: boolean) {
      if (value) {
        return document.body.classList.add("overflow-hidden");
      }
      if (!value) {
        return document.body.classList.remove("overflow-hidden");
      }
    },
  },
})
export default class ErrorModal extends Vue {
  public visible = false;
  public errorMessage = "";
  public redirectLink = "";

  public appVersion: null | string = null;
  public type: "error" | "warning" = "error";

  async created() {
    (window as any).ErrorModal = this;
    if (this.$PLATFORM != "web") {
      const appInfo = await App.getInfo();
      this.appVersion = `v${appInfo.version} ${appInfo.build}`;
    }
  }

  public Show(
    errorMessage: string,
    type: "error" | "warning",
    redirectLink?: string,
  ) {
    try {
      this.type = type;
      this.redirectLink = "";
      this.errorMessage = errorMessage;
      this.visible = true;

      if (redirectLink) {
        this.redirectLink = redirectLink;
      }
    } catch (e) {
      console.error("error create", e);
    }
  }

  public onClickAction() {
    this.visible = false;
    if (this.redirectLink) window.open(this.redirectLink, "_blank");
  }

  public Hide() {
    this.errorMessage = "";
    this.visible = false;
  }

  private onClickOutside() {
    const dialog = document.getElementById("dialog") as HTMLDivElement;
    dialog.classList.add("bounce-modal-animation");
    setTimeout(() => {
      dialog.classList.remove("bounce-modal-animation");
    }, 100);
  }
}
</script>
