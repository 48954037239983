import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["value", "disabled"]
const _hoisted_3 = {
  for: "link-checkbox",
  class: "ml-4 text-sm text-grey"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: "link-checkbox",
      type: "checkbox",
      value: _ctx.model,
      class: "h-8 w-8 rounded-[3px] border border-gray-300 accent-blue",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCheckboxChange && _ctx.onCheckboxChange(...args))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_2),
    _createElementVNode("label", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (!_ctx.label)
        ? _renderSlot(_ctx.$slots, "label", { key: 0 })
        : _createCommentVNode("", true)
    ])
  ]))
}