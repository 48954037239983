<script lang="ts">
import { Options, Vue } from "vue-class-component";
import InputComponent from "@/components/Common/InputComponent.vue";
import { Prop, Watch } from "vue-property-decorator";

@Options({
  components: { InputComponent },
})
export default class ChooseSBPBank extends Vue {
  @Prop() public modelValue!: boolean;

  @Watch("modelValue")
  watchModalValue(new_value: boolean) {
    if (new_value) {
      return document.body.classList.add("overflow-hidden");
    }
    if (!new_value) {
      return document.body.classList.remove("overflow-hidden");
    }
  }
}
</script>

<template>
  <Transition name="slide-up">
    <div
      v-show="modelValue"
      class="fixed z-[40] top-0 right-0 left-0 bottom-0 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="fixed top-0 right-0 left-0 bottom-0 transition-opacity"
        @click="$emit('update:modelValue', false)"
        :class="{ 'bg-gray-500 bg-opacity-75': modelValue }"
      />
      <div
        class="bg-white rounded-lg shadow-xl transform transition-all sm:max-w-lg sm:w-full w-full h-full mt-16 flex flex-col"
      >
        <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div
            class="flex justify-between items-center leading-6 font-medium text-grey"
            id="modal-title"
          >
            <span class="text-blue text-sm opacity-0">Закрыть</span>
            <span class="text-lg text-center">Список банков</span>
            <span
              class="text-blue text-sm font-normal"
              @click="$emit('update:modelValue', false)">Закрыть</span>
          </div>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Воспользуйтесь поиском или выберите из списка
            </p>
          </div>
        </div>
        <div class="overflow-y-scroll flex-grow px-4 mb-10">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}

.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
}
</style>
