<template>
  <div
    class="w-28 h-28 rounded-lg relative p-[2px] border border-primary mr-2"
    @click="emitClick"
  >
    <img
      v-if="!story.onlyBackground"
      :src="story.previewImage"
      class="w-full h-full rounded-lg object-cover"
    />
    <div
      v-else
      :class="story.previewColor"
      class="w-full h-full rounded-lg"
    ></div>
    <div
      class="absolute bottom-3 ml-3 leading-4 text-sm text-white font-medium font-roboto tracking-normal whitespace-pre-line"
      v-if="title"
    >
      {{ title }}
    </div>
  </div>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator";

import { IStory } from "@/struct/IStory/IStory";
import { Vue } from "vue-class-component";

export default class StoryItem extends Vue {
  @Prop({ required: true }) story!: IStory;
  @Prop({ required: false }) title!: string;

  emitClick() {
    this.$emit("click");
  }
}
</script>
