import $api from "@/api/ApiInteceptor";
import { EWebMethod } from "@/struct/IRequest/IRequest";
import { ILinkSupport } from "@/struct/ILinkSupport/ILinkSupport";

export default class ApiLinkSupport {
  public static async getLinkSupport(): Promise<ILinkSupport | boolean> {
    try {
      const response = await $api.call(
        EWebMethod.GET,
        "/api/v4/mobile/driver/link-support",
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }
}
