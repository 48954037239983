<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class CarcIcon extends Vue {
  @Prop() public color!: string;
}
</script>

<template>
  <svg
    width="124"
    height="92"
    viewBox="0 0 124 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4444 0H124V22.7143H0V15.1429C0 6.79062 7.37431 0 16.4444 0ZM0 45.4286H124V92H0.0463135C0.0156224 91.6227 0 91.2416 0 90.8571V45.4286ZM28.7778 75.7143H45.2222C47.4833 75.7143 49.3333 77.4179 49.3333 79.5C49.3333 81.5821 47.4833 83.2857 45.2222 83.2857H28.7778C26.5167 83.2857 24.6667 81.5821 24.6667 79.5C24.6667 77.4179 26.5167 75.7143 28.7778 75.7143ZM57.5556 79.5C57.5556 77.4179 59.4056 75.7143 61.6667 75.7143H94.5556C96.8167 75.7143 98.6667 77.4179 98.6667 79.5C98.6667 81.5821 96.8167 83.2857 94.5556 83.2857H61.6667C59.4056 83.2857 57.5556 81.5821 57.5556 79.5Z"
    />
  </svg>
</template>