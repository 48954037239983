<template>
  <div v-auto-animate="{ duration: 150 }">
    <div
      v-if="isActive && isLoading"
      class="fixed top-0 right-0 left-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50 z-[999] max-w-[600px] max-h-[1000px] m-auto"
    >
      <div
        class="top-0 right-0 left-0 bottom-0 fixed h-screen bg-grey bg-opacity-40"
        style="z-index: -1"
        @click.stop="$emit('end')"
      ></div>

      <div
        class="absolute left-0 right-0 flex h-[2px] rounded-full px-4 z-[1]"
        :class="
          platform === 'web' || platform === 'android' ? 'top-5' : 'top-10'
        "
      >
        <div
          v-for="(slide, index) in story.slides"
          :key="index"
          class="h-full mr-1 rounded-full"
          :class="story.progressColor || 'bg-white'"
          :style="{
            width:
              index <= activeSlideIndex
                ? `${100 / story.slides.length}%`
                : '0%',
          }"
        />
      </div>

      <div
        @click.prevent.stop="$emit('end')"
        class="absolute right-4 h-8 w-8 flex items-center justify-center text-xl bg-white text-gray-400 rounded-full px-4 z-10"
        :class="
          platform === 'web' || platform === 'android' ? 'top-8' : 'top-16'
        "
      >
        <i class="fas fa-times" />
      </div>

      <div
        class="w-full h-full absolute story-overlay"
        v-if="
          currentSlide?.title ||
          currentSlide?.description ||
          currentSlide?.buttonText
        "
      />

      <div class="absolute bottom-8 space-y-6 px-9 z-10 left-0 w-full">
        <p
          class="text-2xl text-white font-medium whitespace-pre-line"
          v-if="currentSlide?.title"
        >
          {{ currentSlide.title }}
        </p>
        <p
          class="text-white text-lg whitespace-pre-line"
          v-if="currentSlide?.description"
        >
          {{ currentSlide.description }}
        </p>
        <ButtonComponent
          v-if="currentSlide?.buttonText"
          :title="currentSlide.buttonText"
          class="text-grey font-medium"
          :color="currentSlide?.buttonColor || 'primary'"
          @clickAction="handleButtonAction"
          :textColor="currentSlide?.buttonTextColor || ''"
        />
      </div>

      <template v-if="!isBackground">
        <img
          v-for="(slide, index) in story.slides"
          :src="imageCache.get(slide.image as string)"
          :key="slide.image"
          class="max-w-full w-full h-full object-cover"
          v-show="activeSlideIndex === index"
        />
      </template>

      <template v-if="isBackground">
        <div
          v-show="activeSlideIndex === index"
          v-for="(slide, index) in story.slides"
          :key="index"
          :class="[
            slide.background,
            'h-full w-full max-w-full absolute top-0 right-0 left-0 bottom-0',
          ]"
        ></div>
      </template>

      <div
        class="w-1/2 h-full absolute left-0 cursor-pointer z-1"
        @click.stop="previousSlide"
      />
      <div
        class="w-1/2 h-full absolute right-0 cursor-pointer z-1"
        @click.stop="nextSlide(true)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import { IStory, IStorySlide } from "@/struct/IStory/IStory";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Capacitor } from "@capacitor/core";

@Options({
  components: { ButtonComponent },
})
export default class StoryViewer extends Vue {
  @Prop({ required: true }) story!: IStory;
  @Prop({ default: false }) isActive!: boolean;
  @Prop({ default: false }) isBackground!: boolean;
  @Prop({ default: false }) background!: string;

  platform = Capacitor.getPlatform();
  activeSlideIndex = 0;
  slideInterval: number | null = null;
  imageCache: Map<string, string> = new Map(); // Cache for images

  private currentSlide: null | IStorySlide = null;
  private isLoading = false;

  @Watch("activeSlideIndex")
  onActiveSlideIndexChanged() {
    this.currentSlide = this.story.slides[this.activeSlideIndex];
  }

  @Watch("isActive")
  onIsActiveChanged(newVal: boolean) {
    if (newVal) {
      this.preloadImages();
      this.currentSlide = this.story.slides[this.activeSlideIndex];
      document.body.classList.add("overflow-hidden");
      document.body.classList.add("relative");
      this.startSlideShow();
    } else {
      this.stopSlideShow();
      document.body.classList.remove("overflow-hidden");
      document.body.classList.remove("relative");
      this.currentSlide = null;
      this.activeSlideIndex = 0;
      this.isLoading = false;
    }
  }

  handleButtonAction() {
    if (this.currentSlide?.buttonAction) {
      this.$emit("storyActionClick", this.currentSlide.buttonAction);
    }
  }

  preloadImages() {
    const loadPromises = this.story.slides.map((slide) => {
      if (this.isBackground) return;

      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          this.imageCache.set(slide.image as string, img.src);
          resolve(img);
        };
        img.onerror = reject;
        img.src = slide.image as string;
      });
    });

    // Wait for all images to load
    Promise.all(loadPromises)
      .then(() => {
        this.isLoading = true;
      })
      .catch((error) => {
        console.error("Failed to preload images", error);
        this.isLoading = false; // You may want to handle this differently
        this.$emit("end");
      });
  }

  startSlideShow() {
    this.stopSlideShow(); // ensure previous interval is cleared
    this.slideInterval = setInterval(() => this.nextSlide(false), 10000); // change slide every 10s
  }

  stopSlideShow() {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
      this.slideInterval = null;
    }
  }

  nextSlide(handleClick: boolean) {
    this.stopSlideShow(); // stop automatic slide show
    if (this.activeSlideIndex < this.story.slides.length - 1) {
      this.activeSlideIndex++;
      this.startSlideShow(); // restart automatic slide show only if it's not the last slide
    } else {
      if (handleClick) {
        this.$emit("end");
      }
    }
  }

  previousSlide() {
    this.stopSlideShow(); // stop automatic slide show
    if (this.activeSlideIndex > 0) {
      this.activeSlideIndex--;
    } else {
      this.$emit("start");
    }
    this.startSlideShow(); // restart automatic slide show
  }
}
</script>

<style>
@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
