export enum EWebMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface IRequest {
  url: string;
  headers: HttpHeaders;
  data?: any;
  params?: HttpParams;
}

interface HttpHeaders {
  [key: string]: string;
}

interface HttpParams {
  [key: string]: string | string[];
}
