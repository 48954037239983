<script lang="ts">
import { Vue, Options } from "vue-class-component";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import confetti from "canvas-confetti";

@Options({})
export default class SuccessInstall extends Vue {
  public isLoading = false;

  mounted() {
    if (this.$IS_PWA) {
      this.$router.replace("/");
      return;
    }
    const myCanvas = document.createElement("canvas");
    const confettiEl = document.getElementById("confetti") as HTMLElement;
    confettiEl.appendChild(myCanvas);
    const myConfetti = confetti.create(myCanvas, { resize: true });

    setTimeout(() => {
      this.isLoading = true;
      myConfetti({ particleCount: 200 });
    }, 8000);
  }

}
</script>

<template>
  <div class="p-4">

    <div class="text-center" v-auto-animate>
      <template v-if="!isLoading">
        <p class="font-medium text-2xl">Подождите, приложение устанавливается</p>
        <p class="text-xl ">Обычно на установку требуется не более 10 секунд</p>
      </template>
      <template v-else>
        <p class="font-medium text-2xl">Приложение успешно установлено</p>
        <p class="text-xl">Перейдите в список приложений и найдите его</p>
      </template>
    </div>


    <div
      class="fixed border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] bottom-[-180px] left-1/2 transform -translate-x-1/2">
      <div class="h-[32px] w-[3px] bg-gray-800 absolute -left-[17px] top-[72px] rounded-l-lg"></div>
      <div class="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
      <div class="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
      <div class="h-[64px] w-[3px] bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
      <div id="confetti" class="z-20 fixed top-0"></div>

      <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white">
        <img src="@/assets/img/store/samsung-wallpaper.jpg" alt=""
             class="w-[272px] h-[572px] object-cover">
        <div class="shadow-sm w-12 h-12 my-3 flex flex-col items-center absolute top-4 left-5 z-10">
          <img :src="require('/assets/logo.png')" alt="logo" :style="{ filter: isLoading ? 'none' : 'blur(1px)' }" />
          <p class="text-xs font-medium text-white mt-[2px] tracking-wide" v-if="!isLoading">Установка</p>
          <p class="text-xs font-medium text-white mt-[2px] tracking-wide" v-else>ФорсМани</p>
        </div>

        <svg class="absolute top-6 left-4 z-20" width="56" height="56" v-if="!isLoading">
          <circle cx="28" cy="28" r="26" stroke="white" stroke-width="4" fill="transparent" />
          <circle class="progress" cx="28" cy="28" r="26" stroke="grey" stroke-width="4" stroke-dasharray="163.362"
                  stroke-dashoffset="163.362" fill="transparent" />
        </svg>
      </div>
    </div>
  </div>
</template>

<style>
@keyframes loading {
  to {
    stroke-dashoffset: 0;
  }
}

.progress {
  animation: loading 8s ease forwards;
}
</style>