export enum ELoginStep {
  EnterPhone,
  CreatePass,
  RepeatPass,
  CheckOTP,
  EnterEmail,
  CheckEmail,
  TelegramRedirect,
}

export enum ELoginStepYandex {
  AuthYandex,
  ChooseNumber,
  CreatePass,
  RepeatPass,
}
