<template>
  <div
    class="relative flex flex-col rounded-md py-2 px-4 h-[116px] text-grey select-none justify-between overflow-hidden"
    :class="[isActive ? 'bg-yellowLight' : 'bg-greyLight']"
  >
    <div class="z-10">{{ title }}</div>
    <div class="z-10 text-2xl font-medium">{{ numberCard }}</div>
    <div v-if="cash">Поступит {{ cash }} р.</div>
    <div v-if="subtitle" class="text-sm">{{ subtitle }}</div>
    <template v-if="checkIcon">
      <svg
        v-if="isActive"
        @click.stop="editCard"
        width="35"
        height="35"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute right-3 top-5 z-10 fill-blue"
      >
        <circle cx="15" cy="15" r="15" />
        <path
          d="M15.8143 11.1852L18.8147 14.1857L12.2995 20.7012L9.62438 20.9965C9.26626 21.0361 8.96369 20.7333 9.00354 20.3752L9.30118 17.6981L15.8143 11.1852ZM20.6704 10.7384L19.2616 9.3296C18.8222 8.89013 18.1095 8.89013 17.67 9.3296L16.3447 10.655L19.3451 13.6555L20.6704 12.3301C21.1099 11.8904 21.1099 11.1779 20.6704 10.7384Z"
          fill="white"
        />
      </svg>
    </template>
    <img
      v-if="isActive"
      class="absolute right-0 rounded-br-md"
      :class="type === 'qiwi' ? 'top-0' : 'bottom-0'"
      :src="require(`../../assets/img/${validateCardIcon()}.svg`)"
      alt="image"
    />
  </div>
</template>

<script lang="ts">
import { Prop } from "vue-property-decorator";
import { Vue } from "vue-class-component";

export default class PayCards extends Vue {
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) subtitle!: string;
  @Prop() cardId!: string;
  @Prop({ default: "" }) numberCard!: string | number;
  @Prop({ default: "" }) rent!: string;
  @Prop({ default: "" }) cash!: string;
  @Prop({ default: "" }) color!: string;
  @Prop({ default: false }) isActive!: boolean;
  @Prop({ default: true }) checkIcon!: boolean;
  @Prop({ default: "" }) type!: string;

  private editCard() {
    this.$router.push("/edit-card/" + this.cardId);
  }

  private validateCardIcon() {
    if (this.type === "Tinkoff") {
      return "tinkoff-card";
    }

    if (this.type === "Sbp") {
      return "sbp";
    }
    return "card";
  }
}
</script>
