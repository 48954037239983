import { createApp } from "vue";
import MainApp from "./App.vue";
import { App } from "@capacitor/app";
import router from "./router";
import store from "./store";
import "./assets/style/tailwind.css";
import "@/assets/style/index.less";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "swiper/css/pagination";
import "swiper/css";
import "vue3-toastify/dist/index.css";
import { TextZoom } from "@capacitor/text-zoom";

import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueMask from "@devindex/vue-mask";
import * as Sentry from "@sentry/vue";
import { Capacitor } from "@capacitor/core";
import { isAndroidWebView, isIosWebview } from "@/script/detect-webview";
import { UAParser } from "ua-parser-js";

const app = createApp(MainApp)
  .use(store)
  .use(autoAnimatePlugin)
  .use(VueMask)
  .use(router);

const isPWAMode =
  window.matchMedia("(display-mode: standalone)").matches ||
  (window.navigator as any).standalone ||
  document.referrer.includes("android-app://");

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const platform = Capacitor.getPlatform();

const isWebview = isSafari
  ? isIosWebview(window)
  : isAndroidWebView(navigator.userAgent);

const detectAppType = async () => {
  // PWA
  if (isPWAMode) return "Онлайн магазин";
  if (platform == "ios" || platform == "android") {
    const appInfo = await App.getInfo().then();
    const appVersion = `v${appInfo.version} ${appInfo.build}. ${platform}`;
    return appVersion;
  }
  return "Веб-версия";
};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://985cfffd2817469d848b34b707fb98a7@o4504714713038848.ingest.sentry.io/4506446848000000",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "https://pro.force2.ru",
      "https://devpro.force2.ru",
      "https://*.force2.ru",
      /^\//,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const parser = new UAParser(navigator.userAgent);

if (platform != "web") {
  Sentry.configureScope(
    (scope: { setTag: (arg0: string, arg1: string) => void }) => {
      detectAppType().then((response) => {
        scope.setTag("version", response);
      });
    },
  );
  TextZoom.get().then((res) => {
    if (res.value > 1) {
      TextZoom.set({ value: 1 });
    }
  });
}

app.config.globalProperties.$PLATFORM = platform;
app.config.globalProperties.$IS_PWA = isPWAMode;
app.config.globalProperties.$IS_SAFARI = isSafari;
app.config.globalProperties.$BROWSER_NAME = parser.getBrowser().name;
app.config.globalProperties.$IS_WEBVIEW = isSafari
  ? isIosWebview(window)
  : isAndroidWebView(navigator.userAgent);

detectAppType().then((response) => {
  app.config.globalProperties.$APP_TYPE = response;
});

if ("serviceWorker" in navigator && platform == "web") {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("sw.js")
      .then(() => {
        console.log("Service worker registered success");
      })
      .catch((error) => {
        console.log("Service worker registration failed:", error);
      });
  });
}

app.mount("#app");
